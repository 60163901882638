@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-buffer-time {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);
  text-align: left;

  .header {
    padding: 1rem;
    border-bottom: 1px solid var(--nylas-base-200);

    h3 {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 20px;
      color: var(--nylas-base-900);
      text-align: left;
    }

    p {
      display: flex;
      gap: 4px;
      align-items: center;
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
    }
  }

  .nylas-buffer-time__body {
    display: grid;
    grid-template-columns: 1fr auto;

    @media #{$mobile} {
      grid-template-columns: 1fr;
    }

    .nylas-buffer-time__dropdown {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border-right: 1px solid var(--nylas-base-200);
      color: var(--nylas-base-800);

      @media #{$mobile} {
        border: none;
      }

      .nylas-buffer-time__row {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 1rem;

        label {
          width: 200px;
        }

        .dropdown-container {
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }

    .nylas-buffer-time__preview {
      width: 208px;
      background: var(--nylas-base-25);
      border-bottom-right-radius: var(--nylas-border-radius-2x);

      @media #{$mobile} {
        width: inherit;
        border-bottom-left-radius: var(--nylas-border-radius-2x);
      }

      h4 {
        font-size: 14px;
        line-height: 24px;
        color: var(--nylas-base-600);
        font-family: var(--nylas-font-family);
        font-weight: 500;
        text-align: center;
        margin: 0.75rem 0;
      }

      .preview-container {
        padding: 0 2rem 1rem;

        .slot {
          background-color: transparent;
          border-top: 1px solid var(--nylas-base-300);

          &.active {
            background-color: var(--nylas-base-100);
          }

          &:last-of-type {
            border-bottom: 1px solid var(--nylas-base-300);
          }
        }

        .event-slot {
          background-color: var(--nylas-base-600);
          border-top: 1px solid var(--nylas-base-300);
        }
      }
    }
  }

  .nylas-buffer-time__dropdown {
    select-dropdown::part(sd_dropdown-button) {
      width: 104px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--nylas-base-200);
      border-radius: var(--nylas-border-radius-2x);
      padding: 1rem;
    }

    select-dropdown::part(sd_dropdown-content) {
      width: 100%;
    }

    select-dropdown::part(sd_dropdown-button-selected-label) {
      max-width: calc(100% - 2rem);
      font-family: var(--nylas-font-family);
      font-size: 16px;
      line-height: 24px;
    }
  }
}
