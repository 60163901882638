import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-booking-confirmation-type` component is a UI component that allows the user to choose a custom redirect URL after a booking is confirmed.
 * @part nbct - The custom booking flow container
 * @part nbct__header - The header of the custom booking flow
 * @part nbct__dropdown - The dropdown container
 * @part nbct__dropdown-button - The dropdown button
 * @part nbct__dropdown-content - The dropdown content
 */
@Component({
  tag: 'nylas-booking-confirmation-type',
  styleUrl: 'nylas-booking-confirmation-type.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasBookingConfirmationType {
  @Element() host!: HTMLNylasBookingConfirmationTypeElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;

  /**
   * @standalone
   * The name of the confirmation redirect link.
   */
  @Prop() name: string = 'confirmation-type';
  /**
   * @standalone
   * The booking type.
   */
  @Prop() bookingType: string = 'booking';
  /**
   * @standalone
   * Show or hide the confirmation type.
   * This prop is automatically determined based on the value of selectedConfiguration in the config,
   * making it unnecessary to set this prop manually.
   */
  @Prop() showComponent: boolean = this.selectedConfiguration?.event_booking?.booking_type !== 'custom-confirmation';
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The selected bookingType.
   */
  @State() selectedBookingType!: string;

  /**
   * The open/close state of the booking confirmation type dropdown.
   */
  @State() showDropdown!: boolean;
  /**
   * This event is fired when the selected bookingType changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-booking-confirmation-type', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-booking-confirmation-type', 'selectedConfigurationChangedHandler', newValue);
    if (newValue?.event_booking?.booking_type) {
      this.selectedBookingType = newValue?.event_booking?.booking_type;
      this.showDropdown = newValue?.event_booking?.booking_type !== 'custom-confirmation' && !!newValue?.scheduler?.organizer_confirmation_url;
    }
  }

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-booking-confirmation-type', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-booking-confirmation-type', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-booking-confirmation-type', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-booking-confirmation-type', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.selectedBookingType = this.bookingType || 'booking';
      this.showDropdown = this.showComponent;
    }
    this.internals.setFormValue(this.selectedBookingType.toString(), this.name);
  }

  componentWillUpdate() {
    debug('nylas-booking-confirmation-type', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-booking-confirmation-type', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-booking-confirmation-type', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-booking-confirmation-type', 'componentDidRender');
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-booking-confirmation-type', 'nylasFormDropdownChangedHandler', event.detail);
    // Pass as handler so that if event.defaultPrevented by parent app, this will be skipped.
    const valueChanged = (event: CustomEvent<{ value: string; name: string }>) => {
      const { value } = event.detail;
      this.selectedBookingType = value;
      this.internals.setFormValue(value.toString(), this.name);
    };
    this.valueChanged.emit({ ...event.detail, valueChanged });
  }

  @RegisterComponent<NylasBookingConfirmationType, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-booking-confirmation-type',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const bookingOptions = [
      {
        label: 'Automatically accept bookings',
        value: 'booking',
      },
      {
        label: 'Manually accept bookings',
        value: 'organizer-confirmation',
      },
    ];

    return (
      <Host>
        {this.showDropdown && (
          <div class="nylas-booking-confirmation-type" part="nbct">
            {bookingOptions.length > 0 && (
              <div class="nylas-booking-confirmation-type__dropdown">
                <span class="label">
                  When a booking is requested
                  <tooltip-component>
                    <info-icon slot="tooltip-icon" />
                    <span slot="tooltip-content">Manually accept bookings allows you to review and either accept or decline each booking request.</span>
                  </tooltip-component>
                </span>
                {this.selectedBookingType && (
                  <select-dropdown
                    name="booking-type"
                    options={bookingOptions}
                    withSearch={false}
                    exportparts="sd_dropdown: nbct__dropdown, sd_dropdown-button: nbct__dropdown-button, sd_dropdown-content: nbct__dropdown-content"
                    defaultSelectedOption={bookingOptions.find(cal => cal.value == this.selectedBookingType)}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Host>
    );
  }
}
