import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug, sanitize } from '@/utils/utils';
import { AttachInternals, Component, Host, State, h, Element, Prop, Watch, Event, EventEmitter } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-cancellation-policy` component is a form input for the cancellation policy message of an event.
 * @part ncpolicy - The cancellation policy container
 * @part ncpolicy__textarea - The cancellation policy textarea
 */
@Component({
  tag: 'nylas-cancellation-policy',
  styleUrl: 'nylas-cancellation-policy.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasCancellationPolicy {
  /**
   * The host element <nylas-cancellation-policy>
   */
  @Element() host!: HTMLElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;

  /**
   * @standalone
   * The cancellation policy stored in the configuration
   */
  @Prop() cancellationPolicy?: string;

  /**
   * @standalone
   * The name of the cancellation policy input.
   */
  @Prop() name: string = 'cancelation-policy';
  /**
   * The selected cancellation policy state. This defaults to the cancellation policy from the configuration or an empty string.
   */
  @State() message!: string;
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-cancellation-policy', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-custom-booking-flow', 'selectedConfigurationChangedHandler', newValue);
    this.message = newValue?.scheduler?.cancellation_policy || this.cancellationPolicy || '';
  }

  /**
   * Event emitted when the cancellation policy message changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  connectedCallback() {
    debug('nylas-cancellation-policy', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-cancellation-policy', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-cancellation-policy', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.message = this.cancellationPolicy || '';
    }
  }

  disconnectedCallback() {
    debug('nylas-cancellation-policy', 'disconnectedCallback');
  }

  handleChange(event) {
    const value = sanitize(event.target.value);
    this.message = value;
    this.internals.setFormValue(value, this.name);
    this.valueChanged.emit({ value: value, name: this.name });
  }

  @RegisterComponent<NylasCancellationPolicy, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-cancellation-policy',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="nylas-cancellation-policy" part="ncpolicy">
          <label htmlFor={this.name}>
            Cancellation policy message
            <span class="label-icon">
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">Attendees receive this message if trying to cancel the event. Attendees are asked to provide a reason for cancellation.</span>
              </tooltip-component>
            </span>
          </label>
          <textarea id={this.name} part="ncpolicy__textarea" name={this.name} value={this.message} maxlength="250" onInput={e => this.handleChange(e)}></textarea>
        </div>
      </Host>
    );
  }
}
