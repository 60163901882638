@import '../../../common/styles/variables.scss';

:host {
  display: block;
  margin: 1rem;
  @include default-css-variables;
}

.nylas-additional-participants__title {
  color: var(--nylas-base-nylas-base-900-aaa, #101323);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
  margin: 0;
  text-align: left;
}

.nylas-additional-participants__subtitle {
  color: var(--nylas-base-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  margin: 0.25rem 0px 0px;
  text-align: left;
}

.nylas-additional-participants__content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nylas-additional-participants__input_group {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    display: flex;
    align-items: center;
    color: var(--nylas-base-800);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.nylas-additional-participants__error {
  color: var(--nylas-error);
  font-size: 14px;
  font-family: var(--nylas-font-family);
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  text-align: left;
}

.nylas-additional-participants__input_wrapper {
  display: flex;
  border-width: 1;
  border-radius: 8px;
  border: 1px solid var(--nylas-base-300);

  &.error {
    border-color: var(--nylas-error);
  }

  button {
    padding: 12px;
    border: none;
    border-left: 1px solid var(--nylas-base-300);
    background: none;
    cursor: pointer;

    &:hover,
    &:active {
      color: var(--nylas-primary);
    }
  }
}

.nylas-additional-participants__input_wrapper_organizer {
  border: none;
}

.nylas-additional-participants__input {
  flex: 1;
  display: flex;
  justify-content: space-between;

  input {
    border: none;
    outline: none;
    background: none;
    width: 100%;
  }

  input-dropdown {
    width: 100%;
  }

  input-dropdown::part(id_dropdown) {
    width: 100%;
    height: 100%;
  }

  input-dropdown::part(id_dropdown-input) {
    height: 100%;
    border-radius: var(--nylas-border-radius-2x);
    border: none;
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
    padding-left: 16px;
  }

  input-component::part(ic__input) {
    background: var(--nylas-base-50);
  }

  input-component::part(ic__label) {
    font-size: 14px;
  }

  input-component {
    div.required-input {
      display: flex;
      gap: 4px;

      label {
        display: flex;
        gap: 4px;
        align-items: center;

        tooltip-component#organizer_participant_tooltip::part(tc__content) {
          left: -84px;
        }
      }
    }
  }
}

.nylas-additional-participants__add {
  padding: 12px;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;

  span {
    padding: 0 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  &:hover,
  &:active {
    color: var(--nylas-primary);
  }
}
