import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { AdditionalParticipant, Calendar, Configuration, Participant } from '@nylas/core';

/**
 * The `nylas-connected-calendars` component is a form input for selecting calendars to check availability for participants.
 * @part nccals - The connected calendars host.
 * @part nccals__header - The header.
 * @part nccals__content - The content.
 * @part nccals__container - The container.
 * @part nccals__title - The title.
 * @part nccals__toggle-container - The toggle container.
 */
@Component({
  tag: 'nylas-connected-calendars',
  styleUrl: 'nylas-connected-calendars.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasConnectedCalendars {
  @Element() host!: HTMLNylasConnectedCalendarsElement;
  private connectedCalendarsFormRef!: HTMLFormElement;

  /**
   * The name of the participants custom availability.
   */
  @Prop() name: string = 'connected-calendars';

  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;

  /**
   * @standalone
   * The participants selected in the add participants section.
   */
  @Prop() participants: Participant[] = this.selectedConfiguration?.participants || [];

  /**
   * @standalone
   * The calendars to choose from for the organizer / logged in user.
   */
  @Prop() calendars?: Calendar[];

  /**
   * @standalone
   * The participant options passed in the additionalParticipants prop
   * from the nylas-scheduler-editor component.
   */
  @Prop() participantOptions?: AdditionalParticipant[];

  /**
   * This event is fired when the participants custom availability changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The state to store the custom availability setting for participants.
   */
  @State() selectedCalendars: {
    [key: string]: {
      isOpen: boolean;
      name: string;
      calendars?: Calendar[];
    };
  } = this.setParticipants(this.participants);

  /**
   * Participants calendar options.
   */
  @State() participantCalendars: {
    [key: string]: Calendar[];
  } = {};

  /**
   * The state to store the default selected calendars for each participant
   */
  @State() participantDefaultSelectedCalendars: {
    [key: string]: string[];
  } = {};

  // Watchers
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-connected-calendars', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('calendars')
  calendarsChangedHandler(newValue: Calendar[]) {
    debug('nylas-connected-calendars', 'calendarsChangedHandler', newValue);
    this.selectedCalendars = this.setParticipants(this.participants);
    this.participantCalendars = this.getParticipantCalendarOptions(this.participants, this.participantOptions);
  }

  @Watch('participants')
  participantsChangedHandler(newValue: Participant[]) {
    debug('nylas-connected-calendars', 'participantsChangedHandler', newValue);
    this.selectedCalendars = this.setParticipants(newValue);
    this.participantCalendars = this.getParticipantCalendarOptions(newValue, this.participantOptions);
  }

  @Watch('participantOptions')
  participantOptionsChangedHandler(newValue: AdditionalParticipant[]) {
    debug('nylas-calendar-picker', 'participantOptionsChangedHandler', newValue);
    this.selectedCalendars = this.setParticipants(this.participants);
    this.participantCalendars = this.getParticipantCalendarOptions(this.participants, newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-calendar-picker', 'selectedConfigurationChangedHandler', newValue);
    const participants = newValue?.participants || this.participants;
    if (participants && participants.length > 0) {
      this.selectedCalendars = this.setParticipants(participants);
      this.participantCalendars = this.getParticipantCalendarOptions(participants, this.participantOptions);
    }
  }

  getParticipantCalendarOptions(addedParticipants: Participant[], availableParticipantOptions: AdditionalParticipant[] | undefined) {
    // Get the participants from the availableParticipantOptions prop that are in the addedParticipants prop
    const organizer = addedParticipants?.find(participant => participant.is_organizer);
    const isRoundRobinConfig = this.selectedConfiguration?.availability?.availability_rules?.availability_method !== 'collective';
    const participantCalendars = {};
    if (availableParticipantOptions) {
      const participants = addedParticipants?.filter(participant => availableParticipantOptions.some(availableParticipant => availableParticipant.email === participant.email));
      const remainingParticipants = addedParticipants?.filter(
        participant => !availableParticipantOptions.some(availableParticipant => availableParticipant.email === participant.email),
      );

      // Get the calendar options for each participant with email as the key
      participants?.forEach(participant => {
        const participantOption = availableParticipantOptions?.find(participantOption => participantOption.email === participant.email);
        if (!participantOption) return;
        this.participantDefaultSelectedCalendars[participant.email] = participant.availability?.calendar_ids || [];
        const calendars = participantOption.calendars ?? [];
        participant.availability?.calendar_ids?.forEach(calendarId => {
          if (!calendars?.some(calendar => calendar.id === calendarId) && calendarId !== 'primary') {
            calendars.push({ id: calendarId, name: calendarId });
          }
        });
        participantCalendars[participant.email] = calendars;
      });
      if (isRoundRobinConfig) {
        // If it is round robin config, add the remaining participant calendars to the participantCalendars
        // (Round-robin does not have an organizer, and we filtered out the participants not passed in the participantOptions prop,
        // so we need to add the remaining participants calendars to the participantCalendars)
        remainingParticipants?.forEach(participant => {
          if (participant?.availability?.calendar_ids && participant?.availability?.calendar_ids?.length > 0 && !participant.is_organizer) {
            participantCalendars[participant.email] = this.calendars ?? [{ id: 'primary', name: participant.email }];
            this.participantDefaultSelectedCalendars[participant.email] = participant.availability?.calendar_ids || [];
          }
        });
      }
    }

    // Add the organizer's calendars to the participantCalendars
    if (organizer) {
      participantCalendars[organizer.email] = this.calendars ?? [{ id: 'primary', name: organizer.email }];
      this.participantDefaultSelectedCalendars[organizer.email] = organizer.availability?.calendar_ids || [];
    }
    return participantCalendars;
  }

  // Lifecycle Methods
  connectedCallback() {
    debug('nylas-connected-calendars', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-connected-calendars', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-connected-calendars', 'componentWillLoad');
  }

  componentDidLoad() {
    debug('nylas-connected-calendars', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedCalendars = this.setParticipants(this.selectedConfiguration?.participants);
      this.participantCalendars = this.getParticipantCalendarOptions(this.selectedConfiguration?.participants, this.participantOptions);
    } else {
      this.selectedCalendars = this.setParticipants(this.participants);
      this.participantCalendars = this.getParticipantCalendarOptions(this.participants, this.participantOptions);
    }
  }

  @Listen('valueChanged')
  handleValueChanged(event: CustomEvent) {
    debug('[nylas-editor-tabs]', 'handleValueChanged', event);
    const { name, value } = event.detail;
    if (!name.startsWith('participant-')) {
      return;
    }
    // Validate the form
    if (!this.connectedCalendarsFormRef.checkValidity()) {
      this.internals.setValidity({ customError: true }, 'Please select at least one calendar for each participant.');
      return;
    } else {
      this.internals.setValidity({});
    }

    const key = name.split('participant-')[1];
    if (!this.selectedCalendars[key]) return;
    this.selectedCalendars[key]['calendars'] = value;
    this.selectedCalendars = { ...this.selectedCalendars };

    const participantsCalendars = {};
    Object.keys(this.selectedCalendars).forEach(key => {
      participantsCalendars[key] = this.selectedCalendars[key].calendars;
    });
    this.internals.setFormValue(JSON.stringify(participantsCalendars), this.name);
    this.valueChanged.emit({ value: JSON.stringify(participantsCalendars), name: this.name });
  }

  setParticipants(participants: Participant[]) {
    const selectedParticipants = {};
    participants?.forEach(participant => {
      if (participant?.availability?.calendar_ids && participant?.availability?.calendar_ids?.length > 0) {
        const isOpen = participant.is_organizer ? true : false;
        selectedParticipants[participant.email] = {
          isOpen: isOpen,
          name: participant.name || participant.email,
          availability: participant.availability,
        };
      }
    });
    return selectedParticipants;
  }
  @RegisterComponent<NylasConnectedCalendars, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-connected-calendars',
    stateToProps: new Map([
      ['schedulerConfig.additionalParticipants', 'participantOptions'],
      ['schedulerConfig.calendars', 'calendars'],
      ['schedulerConfig.selectedConfiguration', 'selectedConfiguration'],
    ]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="nylas-connected-calendars" part="nccals">
          <div class="header" part="nccals__header">
            <h3>Connected Calendars</h3>
            <p>
              Select the calendars to use for checking your availability.
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">
                  Check availability across one or more calendars. If you select multiple calendars for a participant, the participant must be available across all of their
                  calendars to be considered available.
                </span>
              </tooltip-component>
            </p>
          </div>
          <div class="content" part="nccals__content">
            <form ref={el => (this.connectedCalendarsFormRef = el as HTMLFormElement)}>
              {Object.keys(this.participantCalendars).map((key, index) => {
                const participant = this.selectedCalendars[key];
                const participantCalendars = this.participantCalendars[key];
                if (!participant || !participant.name) return;
                return (
                  <div class="participant-container" part="nccals__container" key={`participant-conatiner-${index}`}>
                    <div class="participant-title" part="nccals__title">
                      <p>{participant.name}'s connected calendars</p>
                      <div class="participant-toggle" part="nccals__toggle-container">
                        <span
                          class={`chevron ${participant.isOpen ? 'open' : 'closed'}`}
                          onClick={() => {
                            this.selectedCalendars[key].isOpen = !participant.isOpen;
                            this.selectedCalendars = { ...this.selectedCalendars };
                          }}
                        >
                          <chevron-icon width="24" height="24" />
                        </span>
                      </div>
                    </div>
                    {participant.isOpen && (
                      <nylas-calendar-picker
                        key={key}
                        name={`participant-${key}`}
                        calendars={participantCalendars}
                        defaultSelectedCalendars={this.participantDefaultSelectedCalendars[key]}
                      />
                    )}
                  </div>
                );
              })}
            </form>
          </div>
        </div>
      </Host>
    );
  }
}
