@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-booking-calendar-picker {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);

  .header {
    padding: 1rem;
    border-bottom: 1px solid var(--nylas-base-200);

    h3 {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 20px;
      color: var(--nylas-base-900);
      text-align: left;
    }

    p {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .nylas-booking-calendar-picker__loading {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 1rem 1rem 0;
      color: var(--nylas-base-800);
    }

    .loading-button {
      margin: 0 1rem;
      padding: 0;
      width: inherit;
      height: 48px;
      color: black;
      font-size: 1rem;
      cursor: not-allowed;
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      border: 1px solid var(--nylas-base-200);
      border-radius: var(--nylas-border-radius-2x);

      span {
        padding: 0 1rem;
      }
    }
  }

  .nylas-booking-calendar-picker__dropdown {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 4px;

    span.label {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--nylas-base-800);

      tooltip-component {
        display: flex;
      }
    }

    select-dropdown::part(sd_dropdown) {
      width: 100%;
    }

    select-dropdown::part(sd_dropdown-content) {
      width: 100%;
      max-width: unset;
    }

    select-dropdown::part(sd_dropdown-button) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--nylas-base-200);
      border-radius: var(--nylas-border-radius-2x);
    }

    select-dropdown::part(sd_dropdown-button-selected-label) {
      max-width: calc(100% - 2rem);
      font-family: var(--nylas-font-family);
      font-size: 16px;
      line-height: 24px;
    }
  }
}
