import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { DEFAULT_FORM_FIELD_TYPES, FIELD_TYPES } from '@/common/constants';
import { Configuration } from '@nylas/core';
import Sortable from 'sortablejs';

interface AdditionalFields {
  type: string;
  required: boolean;
  label: string;
  order: number;
  options?: string[];
  key: string;
}
interface AdditionalFieldsInternal extends AdditionalFields {
  isOpen: boolean;
  readonly?: boolean;
  typeLabel: string;
}
/**
 * The `nylas-booking-form-config` component is a form input for adding additional fields to the booking form.
 * @part nbfc__header - The header of the booking form
 * @part nbfc__add_field - The add field selection container
 * @part nbfc__add_field-button - The add field  selection button
 * @part nbfc__add_field-content - The add field  selection dropdown content
 * @part nbfc__additional_fields - The aditional fields container
 * @part nbfc__form-field - The single form container
 * @part nbfc__form-field-header - The single form header
 * @part nbfc__form-field-content - The single form content
 */
@Component({
  tag: 'nylas-booking-form-config',
  styleUrl: 'nylas-booking-form-config.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasBookingFormConfig {
  /**
   * The element <nylas-booking-form-config> itself.
   */
  @Element() host!: HTMLNylasBookingFormConfigElement;
  /**
   * @internal
   * The selected configuration.
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * The name of the booking form config.
   */
  @Prop() name: string = 'booking-form-config';

  /**
   * The additional fields to be displayed on the booking form.
   */
  @Prop() additonalFields?: AdditionalFields[];
  /**
   * This event is fired when the selected availability / open hours change.
   */
  @Event() valueChanged!: EventEmitter<{
    value: Record<string, AdditionalFields>;
    name: string;
  }>;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  @State() formFields!: AdditionalFieldsInternal[];
  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-calendar-picker', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  connectedCallback() {
    debug('nylas-booking-form-config', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-booking-form-config', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-booking-form-config', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
    const staticFields = [
      {
        type: 'text',
        label: 'Your name',
        key: 'your_name',
        typeLabel: 'Short text',
        readonly: true,
        required: true,
        isOpen: false,
        order: 0,
      },
      {
        type: 'email',
        label: 'Your Email',
        key: 'your_email',
        typeLabel: 'Email',
        readonly: true,
        isOpen: false,
        required: true,
        order: 1,
      },
    ];
    this.formFields = [...staticFields];
  }

  componentDidLoad() {
    debug('nylas-booking-form-config', 'componentDidLoad');
    const additionalFields =
      Object.entries(this.selectedConfiguration?.scheduler?.additional_fields || {}).map(([id, field]) => ({ ...(field as AdditionalFields), key: id })) ||
      this.additonalFields ||
      [];
    this.formFields = [
      ...this.formFields,
      ...additionalFields
        .sort((a, b) => a.order - b.order)
        .map((f: AdditionalFields, i) => {
          const { typeLabel } = FIELD_TYPES.find(field => field.type === f.type);
          f.order = i + 2;
          return { ...f, isOpen: false, typeLabel };
        }),
    ];

    const container = this.host.shadowRoot?.getElementById('fields');
    Sortable.create(container, {
      animation: 150,
      swap: true, // Enable swap plugin
      swapClass: 'highlight', // The class applied to the hovered swap item
      onEnd: this.swapFields.bind(this),
      filter: '.fixed', // Disable dragging for elements with the 'fixed' class
      onMove: evt => !evt.related.classList.contains('fixed'), // Prevent moving if target has 'fixed' class
    });
  }

  @Watch('formFields')
  watchHandler(newValue: AdditionalFieldsInternal[]) {
    const additionalFields = newValue;
    // Remove readonly fields, sort by order
    const sortedFields = additionalFields
      .filter(f => !f.readonly)
      .sort((a, b) => a.order - b.order)
      .map((f, i) => {
        const field: AdditionalFields = {
          type: f.type,
          required: f.required,
          label: f.label,
          order: i + 1,
          options: f.options,
          key: f.key,
        };
        return { ...field };
      });
    // Map fields to object
    const addFieldsMap = sortedFields.reduce((acc, field) => {
      acc[field.key] = {
        type: field.type,
        required: field.required,
        order: field.order,
        options: field.options,
        label: field.label,
      };
      return acc;
    }, {});
    this.valueChanged.emit({
      value: addFieldsMap,
      name: this.name,
    });
  }

  /**
   * This function finds the next missing number in an array of strings (keys of additional_fields).
   * We use this function to generate the next consecutive index number for the key.
   * For example,
   * - If the keys are ['dropdown_another-one_1', 'dropdown_Whatever-choose-one_3'] the function will return 2.
   * - If the array is empty, the function will return 1.
   * - If there are no missing numbers in between, the function will return the next number after the last element.
   *
   * @param arr of keys stored in the formFields for the given field type
   * @returns a number that is the next (consecutive) missing number in the array
   */
  findNextMissingNumber(arr: string[]): number {
    if (arr.length === 0) {
      return 1; // If the array is empty, the first number is 1
    }

    // Extract numbers from strings by splitting on underscore and popping the last element
    const numbers = arr
      .map(item => {
        const parts = item.split('_'); // Split the string by '_'
        const lastPart = parts.pop(); // Get the last part (should be the number)
        const parsedNumber = parseInt(lastPart || '', 10);
        return isNaN(parsedNumber) ? null : parsedNumber; // Return the parsed number or null if not a valid number
      })
      .filter((num): num is number => num !== null) // Filter out any null values and assert that the result is a number
      .sort((a, b) => a - b); // Sort numbers in ascending order

    // Iterate through the sorted numbers to find the missing number
    for (let i = 0; i < numbers.length - 1; i++) {
      if (numbers[i + 1] !== numbers[i] + 1) {
        return numbers[i] + 1; // Return the missing number
      }
    }

    // If no number is missing in between, return the next number after the last element
    return numbers.length > 0 ? numbers[numbers.length - 1] + 1 : 1;
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(
    event: CustomEvent<{
      value: string;
      name: string;
    }>,
  ) {
    const { name, value } = event.detail;
    if (name === 'add-field') {
      const field = FIELD_TYPES.find(f => f.type === value);
      const maxOrder = Math.max(...this.formFields.map(f => f.order));
      const existingFieldKeys = this.formFields.filter(f => f.type === field.type).map(f => f.key);
      const next = this.findNextMissingNumber(existingFieldKeys);
      const newField = { ...field, label: field.label, key: `${field.type}_${field.label.split(' ').join('-')}_${next}`, order: maxOrder + 1 }; // Copy object + Ensure unique order
      this.formFields = [...this.formFields, newField];
    }
  }

  @Listen('nylasFormInputChanged')
  nylasFormInputChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    const { name } = event.detail;
    const [fieldIndex, optionIndex] = name.split('_');
    if (optionIndex) {
      this.fieldOptionChange(parseInt(fieldIndex), parseInt(optionIndex), event);
    } else {
      this.fieldLabelChange(parseInt(fieldIndex), event);
    }
  }

  swapFields(event) {
    const { oldIndex, newIndex } = event;
    if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
      const newArray = [...this.formFields].map(f => {
        if (f.order === oldIndex) {
          return {
            ...f,
            order: newIndex,
          };
        }
        if (f.order === newIndex) {
          return {
            ...f,
            order: oldIndex,
          };
        }
        return f;
      });
      this.formFields = newArray;
    }
  }

  fieldToggle(index) {
    this.formFields = this.formFields.map((f, i) => {
      if (index == i) {
        f.isOpen = !f.isOpen;
      }
      return f;
    });
  }
  fieldRemove(index) {
    this.formFields = this.formFields.filter((_, i) => i !== index);
  }

  fieldRequired(index) {
    this.formFields = this.formFields.map((field, i) => {
      if (i === index) {
        field.required = !field.required;
      }
      return field;
    });
  }
  fieldLabelChange(ind, event) {
    const target = event.detail;
    let fieldCopy = {} as AdditionalFieldsInternal;
    this.formFields = this.formFields.map((field, i) => {
      if (i === ind) {
        const existingKey = field.key;
        const indexNumber = existingKey.split('_').pop();
        const label = target.value;
        fieldCopy = {
          ...field,
          label: label,
          key: `${field.type}_${label.split(' ').join('-')}_${indexNumber}`,
        };
        return fieldCopy;
      }
      return field;
    });
  }
  fieldOptionAdd(index) {
    this.formFields = this.formFields.map((field, i) => {
      if (i === index) {
        if (!field.options) {
          field.options = [];
        }
        field.options = [...field.options, ''];
      }
      return field;
    });
  }

  fieldOptionRemove(fieldIndex, index) {
    this.formFields = this.formFields.map((field, i) => {
      if (i === fieldIndex) {
        if (!field.options) {
          field.options = [];
        }
        field.options = field.options.filter((_, i) => i !== index);
      }
      return field;
    });
  }
  fieldOptionChange(fieldIndex, index, event) {
    this.formFields = this.formFields.map((field, i) => {
      if (i === fieldIndex) {
        if (!field.options) {
          field.options = [];
        }
        field.options = field.options.map((o, i) => {
          if (i === index) {
            o = event.detail.value;
          }
          return o;
        });
      }
      return field;
    });
  }

  @RegisterComponent<NylasBookingFormConfig, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-booking-form-config',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="nylas-booking-form-config" part="nbfc">
          <div class="header" part="nbfc__header">
            <div class="header_text">
              <h3>Booking form</h3>
              <p>Add custom fields to the booking form.</p>
            </div>
            <div class="header_action">
              <select-dropdown
                name="add-field"
                exportparts="sd_dropdown: nbfc__add_field, sd_dropdown-button: nbfc__add_field-button, sd_dropdown-content: nbfc__add_field-dropdown-content"
                options={DEFAULT_FORM_FIELD_TYPES}
                withSearch={false}
                withChevron={false}
                dropdownButtonText="Add new field"
              >
                <span slot="select-icon">
                  <plus-icon width="15" height="15"></plus-icon>
                </span>
              </select-dropdown>
            </div>
          </div>
          <div class="content">
            <div id="fields" class="additional_fields" part="nbfc__additional_fields">
              {this.formFields.map((field, i) => {
                return (
                  <div class={`form-field draggable ${field.readonly && 'fixed'}`} part="nbfc__form_field" key={i}>
                    <div class="form-field_header" part="nbfc__form_field-header">
                      <span class="dragable">
                        <dragable-icon width="24" height="25"></dragable-icon>
                      </span>
                      <div class="form-field_header_text">
                        <h4>{field.label}</h4>
                        <p>{field.typeLabel}</p>
                      </div>
                      <div class="form-field_header_actions">
                        <span class={`is-required`}>{field.required ? 'Required' : 'Optional'}</span>
                        {!field.readonly && (
                          <button
                            onClick={() => {
                              this.fieldRemove(i);
                            }}
                          >
                            <close-icon />
                          </button>
                        )}
                        <span
                          class={`chevron ${field.isOpen ? 'open' : 'closed'}`}
                          onClick={() => {
                            this.fieldToggle(i);
                          }}
                        >
                          <chevron-icon width="24" height="24" />
                        </span>
                      </div>
                    </div>
                    <div class={`form-field_content ${!field.isOpen && 'hidden'}`} part="nbfc__form_field-content">
                      <div class="inputs">
                        <input-component class={'label-input'} name={`${i}`} key={i} label="Label" required={false} readOnly={field.readonly} defaultValue={field.label}>
                          <div class="required-input" slot="additional-input">
                            <input
                              type="checkbox"
                              name={`required_${field.order}`}
                              id={`required_${field.order}`}
                              onClick={() => {
                                this.fieldRequired(i);
                              }}
                              checked={field.required}
                              disabled={field.readonly}
                            />

                            <label htmlFor={`required_${field.order}`} aria-label="Required">
                              Required
                            </label>
                          </div>
                        </input-component>
                      </div>
                      {field.options != undefined && (
                        <div class="options-container">
                          <h4>ALL OPTIONS</h4>
                          <div class="options">
                            {field.options.map((o, j) => {
                              return (
                                <div class="option">
                                  <input-component key={j} name={`${i}_${j}`} label={field.typeLabel + ' option ' + (j + 1)} required={true} defaultValue={o}>
                                    {j > 1 && (
                                      <div class="required-input" slot="additional-input">
                                        <button
                                          onClick={() => {
                                            this.fieldOptionRemove(i, j);
                                          }}
                                        >
                                          <close-icon />
                                        </button>
                                      </div>
                                    )}
                                  </input-component>
                                </div>
                              );
                            })}
                          </div>
                          <button
                            onClick={() => {
                              this.fieldOptionAdd(i);
                            }}
                            part="nap__add-time-range"
                          >
                            <add-circle-icon /> Add an option
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
