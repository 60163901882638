import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { checkForMissingSlots, debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Host, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';

/**
 * The `nylas-custom-booking-flow` component is an input form for booking type (automatically or manually accept bookings) and confirmation redirect URL (default or custom).
 * This component is a container for the `nylas-booking-confirmation-type`, `nylas-booking-confirmation-redirect` components.
 *
 * ```html
 * <nylas-custom-booking-flow>
 *   <div slot="inputs">
 *     <nylas-booking-confirmation-type></nylas-booking-confirmation-type>
 *     <nylas-booking-confirmation-redirect></nylas-booking-confirmation-redirect>
 *   </div>
 * </nylas-custom-booking-flow>
 * ```
 *
 * @slot inputs - The custom booking flow inputs. This includes the booking type, confirmation redirect type, and custom redirect URL components.
 */
@Component({
  tag: 'nylas-custom-booking-flow',
  styleUrl: 'nylas-custom-booking-flow.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasCustomBookingFlow {
  @Element() el!: HTMLElement;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-custom-booking-flow', 'connectedCallback');
  }
  disconnectedCallback() {
    debug('nylas-custom-booking-flow', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-custom-booking-flow', 'componentWillLoad');
  }

  componentDidLoad() {
    debug('nylas-custom-booking-flow', 'componentDidLoad');
    checkForMissingSlots(['inputs'], this.el);
  }

  @RegisterComponent<NylasCustomBookingFlow, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-custom-booking-flow',
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host part="ncbflow">
        <nylas-form-card exportparts="nfc__header: ncbflow__header">
          <h1 slot="header-title" class="nylas-custom-booking-flow__title" part="ncbflow__title">
            Custom booking flow
          </h1>
          <div slot="content" class="nylas-custom-booking-flow__content" part="ncbflow__content">
            <slot name="inputs"></slot>
          </div>
        </nylas-form-card>
      </Host>
    );
  }
}
