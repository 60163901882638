import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug, sanitize } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration, EmailTemplate, Participant } from '@nylas/core';

/**
 * The `nylas-confirmation-email` component is a UI component that allows users to set buffer time before and after an event.
 *
 * @part nce__tab-heading - The nylas-confirmation-email main tab heading
 * @part nce - The nylas-confirmation-email container
 * @part nce__header - The header of the event communication section
 * @part nce__body - The body of the event communication section
 * @part nce__confirmation-email-toggle--container - The confirmation email drawer toggle container
 * @part nce__input-image-url - The input image URL container
 * @part nce__summary - The confirmation card summary container
 */
@Component({
  tag: 'nylas-confirmation-email',
  styleUrl: 'nylas-confirmation-email.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasConfirmationEmail {
  @Element() host!: HTMLNylasConfirmationEmailElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The confirmation email template data to display
   */
  @Prop() confirmationEmailTemplate: EmailTemplate = {} as EmailTemplate;
  /**
   * @standalone
   * List of participants
   */
  @Prop() participants: Participant[] = [];
  /**
   * @standalone
   * The name of the component
   */
  @Prop() name: string = 'confirmation-email-template';
  /**
   * Is the confirmation email card open
   */
  @Prop() isOpen: boolean = false;
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The confirmation email template state.
   */
  @State() confirmationEmail!: EmailTemplate;

  /**
   * The internal state to store the isOpen state.
   */
  @State() isConfirmationEmailOpen: boolean = this.isOpen;

  /**
   * The internal state to store the participants.
   */
  @State() participantsState: Participant[] = this.participants;

  /**
   * This event is fired when the email reminders change.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-confirmation-email', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-confirmation-email', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-confirmation-email', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-confirmation-email', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.confirmationEmail = this.confirmationEmailTemplate;
      this.isConfirmationEmailOpen = this.isOpen;
      this.participantsState = this.participants;
    }
  }

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-confirmation-email', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-confirmation-email', 'selectedConfigurationChangedHandler', newValue);
    this.confirmationEmail = newValue?.scheduler?.email_template || this.confirmationEmailTemplate;
    this.isConfirmationEmailOpen = !newValue?.event_booking?.reminders?.length;
    this.participantsState = newValue?.participants || this.participants;
  }

  @Listen('nylasFormInputChanged')
  async nylasFormInputChangeHandler(event: CustomEvent<{ value: string; name: string; type?: string }>) {
    const { name, value } = event.detail;
    const type = event.detail?.type;
    if (name === 'confirmation-email-title') {
      this.confirmationEmail = { ...this.confirmationEmail, booking_confirmed: { ...(this.confirmationEmail?.booking_confirmed || {}), title: value } };
      this.internals.setFormValue(JSON.stringify(this.confirmationEmail), this.name);
      this.valueChanged.emit({ value: JSON.stringify(this.confirmationEmail), name: this.name });
    } else if (name === 'confirmation-email-body' && type === 'multi_line_text') {
      this.handleEmailBodyChange(event);
    }
  }

  @Listen('valueChanged')
  valueChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-confirmation-email', 'valueChangedHandler', event.detail);
    const { value, name } = event.detail;

    if (name === 'confirmation-email-logo') {
      this.confirmationEmail = { ...this.confirmationEmail, logo: value };
      this.valueChanged.emit({ value: JSON.stringify(this.confirmationEmail), name: this.name });
      if (typeof this.internals.setValidity === 'function' && typeof this.internals.setFormValue === 'function') {
        this.internals.setFormValue(JSON.stringify(this.confirmationEmail), this.name);
        this.internals.setValidity({});
      }
    }
  }

  @Listen('nylasFormInputImageUrlInvalid')
  async nylasFormInputImageUrlInvalidHandler(event: CustomEvent<{ value: string; name: string }>) {
    const { value, name } = event.detail;
    if (name === 'confirmation-email-logo' && typeof this.internals.setValidity === 'function') {
      this.internals.setValidity({ customError: true }, value || 'Invalid image URL.');
    }
  }

  updateConfirmationFormValue() {
    debug('nylas-confirmation-email', 'updateConfirmationFormValue');
    this.internals.setFormValue(JSON.stringify(this.confirmationEmail), this.name);
    this.valueChanged.emit({ value: JSON.stringify(this.confirmationEmail), name: this.name });
  }

  toggleConfirmationEmail() {
    this.isConfirmationEmailOpen = !this.isConfirmationEmailOpen;
  }

  handleEmailBodyChange(event) {
    const value = sanitize(event.detail.value);
    this.confirmationEmail = { ...this.confirmationEmail, booking_confirmed: { ...(this.confirmationEmail?.booking_confirmed || {}), body: value } };
    this.internals.setFormValue(JSON.stringify(this.confirmationEmail), this.name);
    this.valueChanged.emit({ value: JSON.stringify(this.confirmationEmail), name: this.name });
  }

  @RegisterComponent<NylasConfirmationEmail, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-confirmation-email',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const organizer = this.participantsState.find(p => p.is_organizer);
    const organizerName = organizer?.name || organizer?.email;

    return (
      <Host>
        <div class="nylas-confirmation-email" part="nce">
          <div class="header" part="nce__header">
            <div>
              <h3>{this.confirmationEmail?.booking_confirmed?.title ?? `Booking confirmed with ${organizerName ? ` ${organizerName}` : ''}`}</h3>
              <p>Booking confirmation email</p>
            </div>
            {this.isConfirmationEmailOpen ? (
              <div class="confirmation-email-toggle" part="nce__confirmation-email-toggle--container">
                <span class={`chevron ${this.isConfirmationEmailOpen ? 'open' : 'closed'} `} onClick={() => this.toggleConfirmationEmail()}>
                  <chevron-icon width="24" height="24" />
                </span>
              </div>
            ) : (
              <button-component
                variant={'basic'}
                clickHandler={event => {
                  event.preventDefault();
                  this.toggleConfirmationEmail();
                }}
              >
                <edit-icon width="16" height="16"></edit-icon>
                Edit
              </button-component>
            )}
          </div>

          {this.isConfirmationEmailOpen ? (
            <div class="nylas-confirmation-email__body" part="nce__body">
              <div class="nylas-confirmation-email__section">
                <div class="nylas-confirmation-email__row">
                  <label>
                    Send confirmation email
                    <span class="label-icon">
                      <tooltip-component>
                        <info-icon slot="tooltip-icon" />
                        <span slot="tooltip-content">You and your guests will receive a confirmation email immediately upon booking.</span>
                      </tooltip-component>
                    </span>
                  </label>
                  <span class="selected-value">Immediately upon booking</span>
                </div>
                <div class="nylas-confirmation-email__row">
                  <div class="subsection">
                    <div class="input-container">
                      <label>
                        Company logo URL
                        <span class="label-icon">
                          <tooltip-component>
                            <info-icon slot="tooltip-icon" />
                            <span slot="tooltip-content">
                              This logo appears in the booking confirmation email. The URL must be publicly accessible. The image will be scaled down to a max size of 200px x
                              100px.
                            </span>
                          </tooltip-component>
                        </span>
                      </label>

                      {this.confirmationEmail && (
                        <input-image-url
                          name="confirmation-email-logo"
                          exportparts="iiu__logo-input-textfield: nce__input-image-url"
                          imageUrl={this.confirmationEmail?.logo ?? ''}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div class="nylas-confirmation-email__row full-width-col">
                  <div class="subsection">
                    <h3>Email message</h3>
                    <div class="input-container">
                      <label htmlFor="title">
                        Custom email title
                        <span class="label-icon">
                          <tooltip-component>
                            <info-icon slot="tooltip-icon" />
                            <span slot="tooltip-content">An email title is a headline within the body of the email. This doesn&apos;t change the subject line.</span>
                          </tooltip-component>
                        </span>
                      </label>
                      <input-component
                        name="confirmation-email-title"
                        maxLength={60}
                        placeholder={`Booking confirmed ${organizerName ? `with ${organizerName}` : ''}`}
                        defaultValue={this.confirmationEmail?.booking_confirmed?.title ?? ''}
                      ></input-component>
                    </div>
                    <div class="input-container">
                      {/* <label htmlFor="title">Email body</label> */}
                      <textarea-component
                        label="Additional info"
                        name="confirmation-email-body"
                        id="confirmation-email-body"
                        placeholder="Default body"
                        tooltip="Scheduler adds the content of the box to the email body."
                        maxLength={500}
                        defaultValue={this.confirmationEmail?.booking_confirmed?.body ?? ''}
                      ></textarea-component>

                      {/* <textarea
                        id="description"
                        part="nrt__textarea"
                        name="confirmation-email-body"
                        placeholder='Default body'
                        value={this.confirmationEmail?.booking_confirmed?.body ?? ''}
                        maxlength="500"
                        rows={2}
                        onInput={e => this.handleEmailBodyChange(e)}
                      ></textarea> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="nylas-confirmation-email__summary" part="nce__summary">
              <p>
                <span class="summary-icon">
                  <person-clipboard-icon></person-clipboard-icon>
                </span>
                All host & guests
              </p>
              <p>
                <span class="summary-icon">
                  <clock-icon></clock-icon>
                </span>
                Immediately on booking
              </p>
            </div>
          )}
        </div>
      </Host>
    );
  }
}
