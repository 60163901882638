@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-confirmation-email {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);
  text-align: left;

  .header {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid var(--nylas-base-200);

    h3 {
      border-radius: var(--nylas-border-radius-2x);
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 20px;
      color: var(--nylas-base-900);
      text-align: left;
    }

    p {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
    }

    .confirmation-email-toggle {
      display: flex;
      gap: 24px;
      align-items: center;

      .chevron {
        display: flex;
        align-self: center;
        cursor: pointer;

        &:hover,
        &:active {
          color: var(--nylas-primary);
        }

        &.open {
          transform: rotate(90deg);
        }

        &.closed {
          transform: rotate(270deg);
        }

        &.disabled {
          cursor: not-allowed;
          color: var(--nylas-base-300);

          &:hover {
            color: var(--nylas-base-300);
          }
        }
      }
    }
  }

  .nylas-confirmation-email__body {
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: var(--nylas-base-25);
    border-radius: var(--nylas-border-radius-2x);

    @media #{$mobile} {
      grid-template-columns: 1fr;
    }

    .nylas-confirmation-email__section {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: var(--nylas-base-800);

      @media #{$mobile} {
        border: none;
      }

      .nylas-confirmation-email__row {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        gap: 1rem;

        &.full-width-col {
          grid-template-columns: 1fr;
          border-top: 1px solid var(--nylas-base-200);
          padding-top: 1.25rem;
          margin: 0.25rem 0;
        }

        label {
          display: flex;
          align-items: center;

          span.required {
            color: var(--nylas-error, #cc4841);
          }

          span.label-icon {
            margin-left: 4px;

            tooltip-component {
              display: flex;
            }
          }
        }

        p {
          margin: 0.25rem 0 0 0;
          font-size: 1rem;
          font-weight: 500;
          line-height: 24px;
          color: var(--nylas-base-900);
        }

        .company-logo-container {
          display: flex;
          gap: 32px;
          width: 100%;

          .input-container {
            display: flex;
            flex-direction: column;
            flex: 1;
          }

          .company-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 100px;
            border: var(--nylas-base-200) solid 1px;
            border-radius: var(--nylas-border-radius-2x);
            overflow: hidden;
            padding: 10px;
          }

          .company-logo img {
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        span.selected-value {
          font-weight: 500;
        }
      }

      .subsection {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h3 {
          margin: 0;
          font-size: 1rem;
          font-weight: 600;
          line-height: 20px;
          color: var(--nylas-base-900);
          text-align: left;
        }

        .input-container {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          textarea {
            padding: 12px 16px;
            border-width: 1;
            resize: vertical;
            border-radius: 8px;
            font-family: var(--nylas-font-family);
            font-size: 16px;
            line-height: 24px;
            border: 1px solid var(--nylas-base-200);
          }
        }
      }
    }

    .nylas-confirmation-email__preview {
      width: 208px;
      background: var(--nylas-base-25);
      border-bottom-right-radius: var(--nylas-border-radius-2x);

      @media #{$mobile} {
        width: inherit;
        border-bottom-left-radius: var(--nylas-border-radius-2x);
      }

      h4 {
        font-size: 14px;
        line-height: 24px;
        color: var(--nylas-base-600);
        font-family: var(--nylas-font-family);
        font-weight: 500;
        text-align: center;
        margin: 0.75rem 0;
      }

      .preview-container {
        padding: 0 2rem 1rem;

        .slot {
          background-color: transparent;
          border-top: 1px solid var(--nylas-base-300);

          &.active {
            background-color: var(--nylas-base-100);
          }

          &:last-of-type {
            border-bottom: 1px solid var(--nylas-base-300);
          }
        }

        .event-slot {
          background-color: var(--nylas-base-600);
          border-top: 1px solid var(--nylas-base-300);
        }
      }
    }

    span.error-message {
      color: var(--nylas-error);
    }

    span.help-text {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
    }
  }

  .nylas-confirmation-email__summary {
    padding: 0.75rem 1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1rem;

    p {
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 4px;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 21px;
      color: var(--nylas-base-600);
      text-align: left;

      span.summary-icon {
        display: flex;
        justify-content: center;
        min-width: 20px;
      }
    }
  }
}

sp-divider {
  background-color: var(--nylas-base-200);
  height: 1px;
  margin: 0.25rem 0;
}
