import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-custom-event-slug` component is a UI component that allows users to add a custom URL slug for their event link.
 *
 * @part nces - The nylas-custom-event-slug container
 * @part nces__body - The body of the event communication section
 * @part nces__header - The header of the event communication section
 * @part nces__drawer-toggle--container - The card's drawer toggle container
 * @part nces__input-textfield - The custom slug input textfield
 */
@Component({
  tag: 'nylas-custom-event-slug',
  styleUrl: 'nylas-custom-event-slug.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasCustomEventSlug {
  @Element() host!: HTMLNylasCustomEventSlugElement;
  /**
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * The default custom event slug
   */
  @Prop() defaultUrlSlug?: string;
  /**
   * Is the custom event slug card expanded
   */
  @Prop() isOpen: boolean = true;
  /**
   * Slug error message
   */
  @Prop() slugErrorMessage: string = '';
  /**
   * The name of the component
   */
  @Prop() name: string = 'custom-event-slug';
  /**
   * Indicates if a slug is required for the configuration.
   * When set to true, the user must enter a slug when creating or editing a configuration.
   * The slug is used for hosted pages that are public.
   */
  @Prop() requiresSlug: boolean = false;
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The URL slug state
   */
  @State() urlSlug!: string;

  /**
   * The focus state of the input container.
   */
  @State() focus = false;

  /**
   * The copied state for the copy to clipboard button
   */
  @State() copied = false;

  /**
   * This event is fired when the slug value changes
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-custom-event-slug', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-custom-event-slug', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-custom-event-slug', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-custom-event-slug', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      console.log('No selected configuration found');
      this.urlSlug = this.defaultUrlSlug || '';
    }
  }

  componentWillUpdate() {
    debug('nylas-custom-event-slug', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-custom-event-slug', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-custom-event-slug', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-custom-event-slug', 'componentDidRender');
  }

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-custom-event-slug', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-custom-booking-flow', 'selectedConfigurationChangedHandler', newValue);
    this.urlSlug = newValue?.slug || this.defaultUrlSlug || '';
  }

  @Listen('nylasFormInputChanged')
  async nylasFormInputChangeHandler(event: CustomEvent<{ value: string; name: string }>) {
    const { name, value } = event.detail;
    this.internals.setValidity({});

    if (name === 'event-slug') {
      this.urlSlug = value;
      this.internals.setFormValue(JSON.stringify(value), this.name);
      this.valueChanged.emit({ value: JSON.stringify(value), name: this.name });
    }
  }

  @Listen('nylasFormInputFocused')
  async nylasFormInputFocusHandler() {
    this.focus = true;
  }

  @Listen('nylasFormInputBlurred')
  async nylasFormInputBlurHandler() {
    this.slugErrorMessage = '';
    this.internals.setValidity({});
    this.focus = false;
    if (this.requiresSlug && this.urlSlug === '') {
      this.internals.setValidity({ customError: true }, 'Slug is required');
      this.slugErrorMessage = 'Slug cannot be blank';
      return;
    }
    if (!/^[A-Za-z0-9-]*$/.test(this.urlSlug)) {
      this.internals.setValidity({ customError: true }, 'Invalid slug: Only letters, numbers, and hyphens are allowed.');
      this.slugErrorMessage = 'Invalid slug: Only letters, numbers, and hyphens are allowed.';
    }
  }

  @Listen('formSubmitted', { target: 'window' })
  formSubmittedHandler(event: CustomEvent) {
    debug('nylas-event-title', 'formSubmittedHandler', event);
    this.nylasFormInputBlurHandler();
  }

  toggleDrawer() {
    this.isOpen = !this.isOpen;
  }

  async copyToClipboard() {
    try {
      await navigator.clipboard.writeText(`${this.urlSlug}`);

      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  @RegisterComponent<NylasCustomEventSlug, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-custom-event-slug',
    stateToProps: new Map([
      ['schedulerConfig.requiresSlug', 'requiresSlug'],
      ['schedulerConfig.selectedConfiguration', 'selectedConfiguration'],
    ]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    if (!this.requiresSlug) return null;
    const isComponentReady = typeof this.urlSlug !== 'undefined';
    return (
      <Host>
        <div class="nylas-custom-event-slug" part="nces">
          <div class="header" part="nces__header">
            <div>
              <h3>Custom event slug</h3>
              <p>Create a custom URL slug for this event</p>
            </div>
            <div class="drawer-toggle" part="nces__drawer-toggle--container">
              <span class={`chevron ${this.isOpen ? 'open' : 'closed'} `} onClick={() => this.toggleDrawer()}>
                <chevron-icon width="24" height="24" />
              </span>
            </div>
          </div>

          {this.isOpen && (
            <div class="nylas-custom-event-slug__body" part="nces__body">
              <div class="nylas-custom-event-slug__section">
                <div class="nylas-custom-event-slug__row">
                  <div class="input-container">
                    <label>
                      Event URL slug
                      <span class="label-icon">
                        <tooltip-component>
                          <info-icon slot="tooltip-icon" />
                          <span slot="tooltip-content">Provide a custom slug for your URL to help uniquely identify your event (letters, numbers, and hyphens only)</span>
                        </tooltip-component>
                      </span>
                    </label>

                    <div class={{ 'nylas-custom-event-slug__input-wrapper': true, 'focus': this.focus, 'error': !!this.slugErrorMessage }}>
                      <div slot="prefix" class="prefix">
                        &#47;
                      </div>
                      {isComponentReady && (
                        <input-component
                          name="event-slug"
                          id="event-slug"
                          type="text"
                          required={false}
                          placeholder="my-link-name"
                          exportparts="ic_input: nces__input-textfield"
                          defaultValue={this.urlSlug}
                        />
                      )}
                      <div slot="icon" class={{ icon: true, disabled: !this.urlSlug }} onClick={() => !this.copied && !!this.urlSlug && this.copyToClipboard()}>
                        {this.copied ? (
                          <div class="copied">
                            <checkmark-icon width="12" height="18" /> Copied!
                          </div>
                        ) : (
                          <copy-icon width="12" height="18" />
                        )}
                      </div>
                    </div>
                    {this.slugErrorMessage && <span class="error-message">{this.slugErrorMessage}</span>}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Host>
    );
  }
}
