@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-booking-form-config {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border: 1px solid var(--nylas-base-200);
  border-radius: var(--nylas-border-radius-2x);

  .header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--nylas-base-200);

    .header_text {
      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: var(--nylas-base-900);
        text-align: left;
      }

      p {
        margin: 0.25rem 0 0 0;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 20px;
        color: var(--nylas-base-600);
        text-align: left;
      }
    }

    .header_action {
      display: flex;

      select-dropdown::part(sd_dropdown-button) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--nylas-base-200);
        border-radius: var(--nylas-border-radius-2x);
        padding: 1rem;
      }
    }
  }

  .content {
    padding: 1rem;
    color: var(--nylas-base-900);
    font-size: 16px;
    font-family: var(--nylas-font-family);

    @media #{$small-mobile} {
      font-size: 15px;
    }

    .additional_fields {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media #{$small-mobile} {
        gap: 0.5rem;
        padding: 0;
      }

      position: relative;
      overflow: auto;

      .form-field {
        border-radius: var(--nylas-border-radius-2x);
        border: 1px solid var(--nylas-base-200);
        padding: 1rem;
        user-select: none;
        background-color: white;
        /* Prevent text selection */
        position: relative;

        &.highlight {
          background-color: var(--nylas-base-200);
        }

        .form-field_header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .dragable {
            cursor: move;
          }

          .form-field_header_text {
            flex: 1;
            text-align: left;
            padding-left: 1rem;

            h4 {
              margin: 0;
              font-size: 16px;
              line-height: 20px;
              color: var(--nylas-base-900);
              text-align: left;
            }

            p {
              margin: 0.25rem 0 0 0;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 20px;
              color: var(--nylas-base-600);
              text-align: left;
            }
          }

          .form-field_header_actions {
            display: flex;
            align-items: center;

            .is-required {
              color: var(--nylas-base-500);
              background-color: var(--nylas-base-50);
              font-weight: 600;
              padding: 4px 8px;
              border-radius: var(--nylas-border-radius-2x);
            }

            button {
              border: none;
              background: transparent;
              height: 40px;
              margin: 0 1rem;
              cursor: pointer;
              color: var(--nylas-base-800);

              &:hover,
              &:active {
                color: var(--nylas-primary);
              }
            }

            .chevron {
              display: flex;
              align-self: center;
              cursor: pointer;

              &:hover,
              &:active {
                color: var(--nylas-primary);
              }

              &.open {
                transform: rotate(90deg);
              }

              &.closed {
                transform: rotate(270deg);
              }
            }
          }
        }

        .form-field_content {
          padding: 1.5rem 0 0 2.7rem;

          &.hidden {
            display: none;
          }

          .inputs {
            display: flex;

            .label-input {
              padding: 0 1rem 0 0;
            }

            .required-input {
              display: flex;

              label {
                padding: 0.5rem;
              }

              input[type='checkbox'] {
                transform: scale(1.3);
              }
            }
          }

          .options-container {
            h4 {
              margin: 0;
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              padding: 0.5rem 0;
              color: var(--nylas-base-900);
              text-align: left;
            }

            button {
              border: none;
              background: transparent;
              height: 40px;
              margin: 1rem 0;
              cursor: pointer;
              color: var(--nylas-base-800);
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:hover,
              &:active {
                color: var(--nylas-primary);
              }
            }

            .options {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .option {
                display: flex;
                justify-content: space-between;
                align-items: center;

                button {
                  border: none;
                  background: transparent;
                  height: 40px;
                  margin: 0 1rem;
                  cursor: pointer;
                  color: var(--nylas-base-800);

                  &:hover,
                  &:active {
                    color: var(--nylas-primary);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
