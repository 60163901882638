import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Host, Prop, State, Watch, h, Element, Listen, EventEmitter } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Event } from '@stencil/core';
import { AdditionalParticipant, Calendar, Configuration } from '@nylas/core';
import { User } from '@/common/nylas-api-request';

type Participant = {
  name?: string;
  email: string;
  is_valid?: boolean;
  is_organizer?: boolean;
  availability?: Availability;
  booking?: Booking;
};
type Availability = {
  calendar_ids: string[];
};
type Booking = {
  calendar_id: string;
};

/**
 * The `nylas-additional-participants` component is a form input for adding additional participants to an event.
 *
 * @part nap__title - The title of the component.
 * @part nap__subtitle - The subtitle of the component.
 * @part nap__content - The content of the component.
 * @part nap__input_group - The input group of the component.
 * @part nap__input_wrapper - The input wrapper of the component.
 * @part nap__input - The input of the component.
 * @part nap__remove-participant - The remove participant button of the component.
 * @part nap__add-participant - The add participant button of the component.
 *
 */
@Component({
  tag: 'nylas-additional-participants',
  styleUrl: 'nylas-additional-participants.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasAdditionalParticipants {
  @Element() host!: HTMLNylasAdditionalParticipantsElement;
  /**
   * @standalone
   * The name of the component
   */
  @Prop() name: string = 'participants';
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The logged in user
   */
  @Prop() currentUser?: User;
  /**
   * @standalone
   * The list of user's calendars.
   */
  @Prop() currentUserCalendars?: Calendar[];
  /**
   * @standalone
   * The additional participants options from the config
   */
  @Prop() participantOptions?: AdditionalParticipant[];
  /**
   * @standalone
   * The event participants
   */
  @Prop() eventParticipants?: Participant[];

  @AttachInternals() internals!: ElementInternals;
  @State() participants: Participant[] = this.eventParticipants ?? [];
  @State() participantErrors: { [key: string]: string } = {};
  @State() includeOrganizerAsParticipant: boolean = true;
  @State() isRoundRobinConfig: boolean = this.selectedConfiguration?.availability?.availability_rules?.availability_method !== 'collective';
  @State() error: string = '';

  /**
   * This event is fired when the selected participants change.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-additional-participants', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-additional-participants', 'selectedConfigurationChangedHandler', newValue);
    this.isRoundRobinConfig = newValue?.availability?.availability_rules?.availability_method !== 'collective';
    this.participants = newValue?.participants || [];
    this.updateOrganizerAsParticipant();
  }

  connectedCallback() {
    debug('nylas-additional-participants', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-additional-participants', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-additional-participants', 'componentDidLoad');
    this.isRoundRobinConfig = this.selectedConfiguration?.availability?.availability_rules?.availability_method !== 'collective';
    this.includeOrganizerAsParticipant = this.isRoundRobinConfig && this.participants.find(p => p.email === this.currentUser?.email) ? true : false;

    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.updateOrganizerAsParticipant();
    }
  }

  disconnectedCallback() {
    debug('nylas-additional-participants', 'disconnectedCallback');
  }

  /**
   * This method is essentially a workaround to check if the internals are available because
   * the unit tests in stencil do not support the internals.
   * @returns boolean
   */
  get isInternalsAvailable() {
    return this.internals !== undefined && typeof this.internals.setFormValue === 'function' && typeof this.internals.setValidity === 'function';
  }

  @Listen('inputOptionChanged')
  onInputOptionChanged(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-additional-participants', 'onInputOptionChanged');
    const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const index = event.detail.name;
    const participant = this.participantOptions?.find(participant => participant.email === event.detail.value);

    if (!participant && this.isRoundRobinConfig) {
      this.isInternalsAvailable && this.internals.setValidity({ customError: true }, 'For round robin configuration, participant should be selected from the dropdown.');
      this.participantErrors[index] = 'For round robin page, participant should be selected from the dropdown.';
      this.participants[index].is_valid = false;
      this.participants = [...this.participants];
      return;
    } else if (!participant && !EMAIL_REGEX.test(event.detail.value)) {
      this.isInternalsAvailable && this.internals.setValidity({ customError: true }, 'Please enter a valid email address');
      this.participantErrors[index] = 'Please enter a valid email address';
      this.participants[index].is_valid = false;
      this.participants = [...this.participants];
      return;
    } else {
      this.participantErrors[index] = '';
      this.participants[index].is_valid = true;
      this.isInternalsAvailable && this.internals.setValidity({});
    }
    this.participants[index].email = event.detail.value;
    // Default to the first calendar if participant was picked from the dropdown options
    this.participants[index].availability = participant && participant.calendars[0].id ? { calendar_ids: [participant.calendars[0].id] } : undefined;
    // If round-robin config, set booking calendar to first calendar for all participants
    this.participants[index].booking = participant && this.isRoundRobinConfig && participant.calendars[0].id ? { calendar_id: participant.calendars[0].id } : undefined;
    this.participants[index].name = participant?.name || event.detail.value;
    this.participants = [...this.participants];
    this.updateFormValue();
  }

  updateOrganizerAsParticipant() {
    const findCurrentUserInParticipants = this.participants.find(p => p.email === this.currentUser?.email);
    if (this.isRoundRobinConfig && !findCurrentUserInParticipants && this.currentUser?.email) {
      const bookingCalendarDefault = this.currentUserCalendars?.find(calendar => calendar.is_primary)?.id || this.currentUserCalendars?.[0]?.id || 'primary';
      this.participants = [
        {
          name: this.currentUser?.name,
          email: this.currentUser?.email,
          is_organizer: true,
          availability: {
            calendar_ids: [bookingCalendarDefault],
          },
          booking: {
            calendar_id: bookingCalendarDefault,
          },
        },
        ...this.participants,
      ];
    }
    this.updateFormValue();
  }

  addParticipant() {
    debug('nylas-additional-participants', 'addParticipant');
    this.participants = [...this.participants, { name: '', email: '', is_organizer: false }];
    this.updateFormValue();
  }
  removeParticipant(index: number) {
    debug('nylas-additional-participants', 'removeParticipant');
    this.participants = this.participants.filter((_, i) => i !== index);
    this.updateFormValue();
  }

  updateFormValue() {
    debug('nylas-additional-participants', 'updateFormValue');
    const participants = this.isRoundRobinConfig ? (this.includeOrganizerAsParticipant ? this.participants : this.participants.filter(p => !p.is_organizer)) : this.participants;
    if (participants.length === 0) {
      this.isInternalsAvailable && this.internals.setValidity({ customError: true }, 'Please add at least one participant');
      this.error = 'Please add at least one participant';
    } else {
      this.isInternalsAvailable && this.internals.setValidity({});
      this.error = '';
      this.isInternalsAvailable && this.internals.setFormValue(JSON.stringify(participants), this.name);
      this.valueChanged.emit({ value: JSON.stringify(participants), name: this.name });
    }
  }

  // Filter out the participants that are already added
  getArrayDifference(array1: AdditionalParticipant[], array2: Participant[]) {
    const filtered = array1.filter(participant1 => !array2.some(participant2 => participant1.email === participant2.email));
    return filtered.map(participant => {
      return { value: participant.email, label: participant.email };
    });
  }

  @RegisterComponent<NylasAdditionalParticipants, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-additional-participants',
    stateToProps: new Map([
      ['schedulerConfig.additionalParticipants', 'participantOptions'],
      ['schedulerConfig.selectedConfiguration', 'selectedConfiguration'],
      ['schedulerConfig.currentUser', 'currentUser'],
      ['schedulerConfig.calendars', 'currentUserCalendars'],
    ]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host part="nap">
        <nylas-form-card>
          <h3 slot="header-title" class="nylas-additional-participants__title" part="nap__title">
            Participants
          </h3>
          <p slot="header-subtitle" class="nylas-additional-participants__subtitle" part="nap__subtitle">
            Add people in your team or organization to join the event.
          </p>
          <div slot="content" class="nylas-additional-participants__content">
            <div>
              {this.participants.map((participant, index) => {
                return (
                  <div class={'nylas-additional-participants__input_group'} part="nap__input_group">
                    {!participant.is_organizer && <label>{`Participant ${index}`}</label>}
                    <div
                      part="nap__input_wrapper"
                      class={{
                        'nylas-additional-participants__input_wrapper': true,
                        'nylas-additional-participants__input_wrapper_organizer': participant?.is_organizer === true,
                        'nylas-additional-participants__input_wrapper_invalid': participant.is_valid === false,
                        'error': participant.is_valid === false,
                      }}
                    >
                      <div class={{ 'nylas-additional-participants__input': true, 'organizer': participant?.is_organizer === true }} part="nap__input">
                        {participant.is_organizer ? (
                          <input-component
                            class={'label-input'}
                            name={`${index}`}
                            key={index}
                            label="Organizer"
                            required={false}
                            readOnly={participant.is_organizer}
                            defaultValue={participant.email}
                          >
                            {this.isRoundRobinConfig && (
                              <div class="required-input" slot="additional-input">
                                <input
                                  type="checkbox"
                                  name={`organizer_participant`}
                                  id={`organizer_participant`}
                                  onClick={() => {
                                    this.includeOrganizerAsParticipant = !this.includeOrganizerAsParticipant;
                                    this.updateFormValue();
                                  }}
                                  checked={this.includeOrganizerAsParticipant}
                                />
                                <label htmlFor={`organizer_participant`} aria-label="Include as participant">
                                  Participant
                                  <tooltip-component id="organizer_participant_tooltip">
                                    <info-icon slot="tooltip-icon" />
                                    <span slot="tooltip-content">
                                      <strong>Include Organizer:</strong> The organizer (you) will be included in the round-robin rotation. <br />
                                      <strong>Exclude Organizer:</strong> The organizer (you) will not be included in the round-robin rotation.
                                    </span>
                                  </tooltip-component>
                                </label>
                              </div>
                            )}
                          </input-component>
                        ) : (
                          <input-dropdown
                            id={`${index}`}
                            name={`${index}`}
                            filterable={true}
                            inputValue={participant.email}
                            options={this.getArrayDifference(this.participantOptions || [], this.participants)}
                          />
                        )}
                        {!participant.is_organizer && (
                          <button onClick={() => this.removeParticipant(index)} part="nap__remove-participant">
                            <close-icon />
                          </button>
                        )}
                      </div>
                    </div>
                    {!participant.is_valid && (
                      <p class="nylas-additional-participants__error" part="nap__error">
                        {this.participantErrors[index]}
                      </p>
                    )}
                  </div>
                );
              })}
              <p class="nylas-additional-participants__error" part="nap__error">
                {this.error}
              </p>
            </div>
            <button class="nylas-additional-participants__add" part="nap__add-participant" onClick={() => this.addParticipant()}>
              <add-circle-icon /> <span>Add {this.participants.length > 1 ? 'another' : 'a'} participant</span>
            </button>
          </div>
        </nylas-form-card>
      </Host>
    );
  }
}
