import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { Calendar } from '@nylas/core';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { User } from '@/common/nylas-api-request';

/**
 * The `nylas-calendar-picker` component is a form input for selecting calendars to check availability.
 *
 * @part ncp - The calendar picker container
 * @part ncp__dropdown - The dropdown container
 * @part ncp__dropdown-button - The dropdown button
 * @part ncp__dropdown-content - The dropdown content
 */
@Component({
  tag: 'nylas-calendar-picker',
  styleUrl: 'nylas-calendar-picker.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasCalendarPicker {
  @Element() host!: HTMLNylasCalendarPickerElement;
  /**
   * @standalone
   * The name of the calendar picker.
   */
  @Prop() name: string = 'calendar';
  /**
   * @standalone
   * The logged in user.
   */
  @Prop() currentUser?: User;
  /**
   * @standalone
   * The calendars to choose from.
   */
  @Prop() calendars?: Calendar[];
  /**
   * @standalone
   * The default selected calendars.
   */
  @Prop() defaultSelectedCalendars: string[] = [];
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The selected calendars.
   */
  @State() selectedCalendars: string[] = [];

  /**
   * The error message to display.
   */
  @State() error: string = '';

  /**
   * This event is fired when the selected calendars change.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string[];
    name: string;
    valueChangedHandler?: (event: CustomEvent<{ value: string[]; name: string }>) => void;
  }>;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-calendar-picker', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-calendar-picker', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-calendar-picker', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-calendar-picker', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-calendar-picker', 'componentDidLoad');
    // TODO: Remove this when the internals in tests are fixed.
    if (typeof this.internals.setFormValue === 'function') {
      this.internals.setFormValue(this.defaultSelectedCalendars.toString(), 'calendar');
      this.valueChanged.emit({ value: this.defaultSelectedCalendars, name: this.name });
    }
  }

  componentWillUpdate() {
    debug('nylas-calendar-picker', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-calendar-picker', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-calendar-picker', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-calendar-picker', 'componentDidRender');
  }

  @Listen('selectedOptionsChanged')
  selectedOptionsChangedHandler(event: CustomEvent<{ value: string[]; name: string }>) {
    debug('nylas-calendar-picker', 'nylasFormDropdownChangedHandler', event.detail);
    // Pass as handler so that if event.defaultPrevented by parent app, this will be skipped.
    const valueChangedHandler = (event: CustomEvent<{ value: string[]; name: string }>) => {
      const { value } = event.detail;
      this.selectedCalendars = [...value];
      if (this.selectedCalendars.length === 0) {
        this.error = `Select at least one calendar.`;
        this.internals.setValidity({ customError: true }, `Select at least one calendar.`);
      } else {
        this.error = '';
        this.internals.setValidity({});
      }
      this.internals.setFormValue(value.toString(), this.name);
    };
    this.valueChanged.emit({ ...event.detail, name: this.name, valueChangedHandler });
  }

  @RegisterComponent<NylasCalendarPicker, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-calendar-picker',
    stateToProps: new Map([['schedulerConfig.currentUser', 'currentUser']]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string[]; name: string; valueChangedHandler?: (event: CustomEvent<{ value: string[]; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChangedHandler } = event.detail;
        // If a handler is passed, call it.
        if (valueChangedHandler) {
          valueChangedHandler(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const calendarOptions =
      this.calendars?.map(calendar => {
        return {
          value: calendar.id,
          label: calendar.name,
          selected:
            this.defaultSelectedCalendars?.includes(calendar.id) ||
            (this.defaultSelectedCalendars?.includes('primary') || this.defaultSelectedCalendars?.length === 0
              ? calendar.id === this.currentUser?.email || calendar.id === this.name.split('participant-')[1]
              : false),
        };
      }) ?? [];

    return (
      <Host>
        <div class="nylas-calendar-picker" part="ncp">
          {calendarOptions.length > 0 ? (
            <div class="nylas-calendar-picker__dropdown">
              <multi-select-dropdown
                name={this.name}
                options={calendarOptions}
                error={this.error}
                exportparts="msd_dropdown: ncp__dropdown, msd_dropdown-button: ncp__dropdown-button, msd_dropdown-content: ncp__dropdown-content"
              ></multi-select-dropdown>
            </div>
          ) : (
            <div class="nylas-calendar-picker__loading">
              <span class="label">
                Select calendars that will be checked for availability <info-icon />
              </span>
              <div class="loading-button">
                <span>Loading...</span>
              </div>
            </div>
          )}
        </div>
      </Host>
    );
  }
}
