import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { isValidUrl } from '@/utils/utils';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-booking-confirmation-redirect` component is a UI component that allows the user to choose a custom redirect URL after a booking is confirmed.
 * @part nbcr - The booking redirect container
 * @part nbcr__header - The header of the booking redirect
 * @part nbcr__dropdown - The dropdown container
 * @part nbcr__dropdown-button - The dropdown button
 * @part nbcr__dropdown-content - The dropdown content
 * @part nbcr__input-textfield - The input field content
 */
@Component({
  tag: 'nylas-booking-confirmation-redirect',
  styleUrl: 'nylas-booking-confirmation-redirect.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasBookingConfirmationRedirect {
  @Element() host!: HTMLNylasBookingConfirmationRedirectElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The name of the confirmation redirect link.
   */
  @Prop() name: string = 'confirmation-redirect';
  /**
   * @standalone
   * The custom redirect URL.
   */
  @Prop() redirectUrl: string = '';
  /**
   * @standalone
   * The custom redirect URL error message
   */
  @Prop() redirectUrlErrorMessage: string = '';
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The current redirect URL.
   */
  @State() currentRedirectUrl: string = '';
  /**
   * The state for whether a custom URL can be added.
   */
  @State() acceptUrl!: boolean;

  @State() componentLoaded: boolean = false;

  /**
   * This event is fired when the current redirect URL changes and is valid.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-booking-confirmation-redirect', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-booking-confirmation-redirect', 'selectedConfigurationChangedHandler', newValue);
    if (newValue?.scheduler?.confirmation_redirect_url) {
      this.currentRedirectUrl = newValue?.scheduler?.confirmation_redirect_url;
      this.acceptUrl = newValue?.scheduler?.confirmation_redirect_url !== '';
    }
  }

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-booking-confirmation-redirect', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-booking-confirmation-redirect', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-booking-confirmation-redirect', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-booking-confirmation-redirect', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.currentRedirectUrl = this.redirectUrl;
      this.acceptUrl = this.redirectUrl !== '';
    }
    this.componentLoaded = true;
    if (typeof this.internals.setFormValue === 'function') {
      this.internals.setFormValue(this.currentRedirectUrl.toString(), this.name);
    }
  }

  componentWillUpdate() {
    debug('nylas-booking-confirmation-redirect', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-booking-confirmation-redirect', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-booking-confirmation-redirect', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-booking-confirmation-redirect', 'componentDidRender');
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-booking-confirmation-redirect', 'nylasFormDropdownChangedHandler', event.detail);

    const { name, value } = event.detail;

    if (name === 'confirmation-redirect-dropdown') {
      if (value === 'custom') {
        this.acceptUrl = true;
      } else {
        this.acceptUrl = false;

        // Submit empty string to clear redirect URL
        this.internals.setFormValue('', this.name);
        this.valueChanged.emit({ name: this.name, value: '' });
      }
    }
  }

  @Listen('nylasFormInputBlurred')
  nylasFormInputBlurHandler(event: CustomEvent<{ value: string; name: string }>) {
    const { name, value } = event.detail;
    this.currentRedirectUrl = value;

    if (this.acceptUrl && name === 'confirmation-redirect') {
      this.redirectUrlErrorMessage = '';

      if (!isValidUrl(this.currentRedirectUrl)) {
        this.internals.setValidity({ customError: true }, 'Please enter a valid URL.');
        this.redirectUrlErrorMessage = 'Please enter a valid URL.';
        return;
      }
      this.internals.setValidity({});

      // Pass as handler so that if event.defaultPrevented by parent app, this will be skipped.
      const valueChanged = (event: CustomEvent<{ value: string; name: string }>) => {
        const { value } = event.detail;
        this.internals.setFormValue(value.toString(), this.name);
      };
      this.valueChanged.emit({ ...event.detail, valueChanged });
    }
  }

  @Listen('formSubmitted', { target: 'document' })
  formSubmittedHandler(_event: CustomEvent) {
    if (this.acceptUrl && !isValidUrl(this.currentRedirectUrl)) {
      this.internals.setValidity({ customError: true }, 'Please enter a valid URL.');
      this.redirectUrlErrorMessage = 'Please enter a valid URL.';
    } else {
      this.internals.setValidity({});
    }
  }

  @RegisterComponent<NylasBookingConfirmationRedirect, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-booking-confirmation-redirect',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const redirectOptions = [
      {
        label: 'Display default confirmation page',
        value: 'default',
      },
      {
        label: 'Redirect to custom URL',
        value: 'custom',
      },
    ];

    return (
      <Host>
        <div class="nylas-booking-confirmation-redirect" part="nbcr">
          {redirectOptions.length > 0 && (
            <div class="nylas-booking-confirmation-redirect__dropdown">
              <span class="label">
                When a booking is confirmed
                <tooltip-component>
                  <info-icon slot="tooltip-icon" />
                  <span slot="tooltip-content">You can either display the default confirmation page or redirect users to your own confirmation page.</span>
                </tooltip-component>
              </span>
              {this.componentLoaded && (
                <select-dropdown
                  name="confirmation-redirect-dropdown"
                  options={redirectOptions}
                  withSearch={false}
                  defaultSelectedOption={redirectOptions.find(option => option.value === (this.acceptUrl ? 'custom' : 'default'))}
                  exportparts="sd_dropdown: nbcr__dropdown, sd_dropdown-button: nbcr__dropdown-button, sd_dropdown-content: nbcr__dropdown-content"
                />
              )}
              {this.acceptUrl && (
                <div>
                  <input-component
                    name="confirmation-redirect"
                    id="confirmation-redirect"
                    type="text"
                    placeholder="Enter custom redirect URL"
                    required={false}
                    part="ic__input_wrapper: nbcr__input-textfield"
                    defaultValue={this.currentRedirectUrl}
                  />
                  {this.redirectUrlErrorMessage && <span class="error-message">{this.redirectUrlErrorMessage}</span>}
                </div>
              )}
            </div>
          )}
        </div>
      </Host>
    );
  }
}
