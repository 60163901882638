@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-customize-booking-settings {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);
  .header {
    padding: 1rem;
    border-bottom: 1px solid var(--nylas-base-200);
    h3 {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 20px;
      color: var(--nylas-base-900);
      text-align: left;
    }
    p {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
    }
  }
  .nylas-customize-booking-settings__settings {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    margin: 1rem 0;
    .nylas-customize-booking-settings__settings-div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.label {
  margin-left: 32px;
  font-size: 16px;
  font-weight: 400;
  color: var(--nylas-base-800);
  line-height: 24px;
  .label-contents {
    margin: 0;
    display: inline-block;
    @media #{$mobile} {
      display: block;
    }
  }
  tooltip-component {
    display: inline-block;
  }
}

tooltip-component#guests::part(tc__content) {
  @media #{$mobile} {
    left: 58px;
  }
}

tooltip-component#reschedule::part(tc__content) {
  left: 58px;
  @media #{$mobile} {
    left: 58px;
  }
}

tooltip-component#cancel::part(tc__content) {
  left: -58px;
  @media #{$mobile} {
    left: 58px;
  }
}
