import { dataResponse } from '@/utils/utils';
import type { NylasSessions } from '@nylas/identity';
import { DataResponseReturnType } from './types';

/**
 * Arguments for making a request to the Nylas API.
 */
export type NylasRequestArgs = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  path: string;
  body?: any;
  headers?: Record<string, string>;
};

/**
 * A model representing a user in the Nylas Identity system.
 */
export type User = {
  id: string;
  email: string;
  name?: string;
  provider?: string;
};

/**
 * Arguments for authenticating a user with the Nylas Identity system.
 */
export type AuthArgs = {
  /**
   * The provider of the user's email address.
   */
  provider?: string;
  /**
   * A list of permission scopes for the provider.
   */
  scope?: Array<string>;
  /**
   * The login hint associated with the user.
   */
  loginHint?: string;
  /**
   * Whether to include grant scopes in the auth exchange.
   */
  includeGrantScopes?: boolean;
  prompt?: string;
  metadata?: string;
  state?: string;
};

/**
 * Interface for making requests to the Nylas API.
 *
 * This is an abstract class that is meant to be implemented by customers to provide their own
 * implementation of making requests to the Nylas API. This allows customers to use their own
 * authentication mechanism, or to use a different HTTP client library.
 */
export abstract class NylasApiRequest {
  /**
   * This method should make an authenticated request to the Nylas API.
   * Note: It should not throw an error if the request fails, but should return an object with the error
   * information as part of the tuple returned.
   * @param args NylasRequestArgs
   */
  abstract request<T = any>(args: NylasRequestArgs): Promise<DataResponseReturnType<T>>;

  /**
   * This method should return the current user's information.
   */

  abstract currentUser(): Promise<User | null>;

  /**
   * This method should set the default authentication arguments to use when authenticating the user.
   */
  abstract setDefaultAuthArgs(authArgs: AuthArgs[]): void;

  /**
   * This method should return the URL to redirect the user to for authentication.
   */
  abstract authenticationUrl(authArgs?: AuthArgs): Promise<string | undefined>;
}

/**
 * A wrapper around the Nylas Identity session that implements the NylasApiRequest interface.
 * This class is used by the Nylas Web Elements to make requests to the Nylas API if no custom
 * implementation of NylasApiRequest is provided.
 */
export class NylasIdentityRequestWrapper implements NylasApiRequest {
  /**
   * The Nylas Identity session instance.
   */
  private session: NylasSessions;

  /**
   * The grant_id to use for requests, if there is multi-account support
   * enabled in the Nylas Identity settings.
   */
  private activeGrantId?: string;

  /**
   * The default authentication arguments to use when authenticating the user.
   */
  private defaultAuthArgs: AuthArgs[] = [];

  /**
   * Constructor for the NylasIdentityRequestWrapper class.
   * @param session The Nylas Identity session instance.
   */
  constructor(session: NylasSessions) {
    this.session = session;
  }

  /**
   * This method sets the grant_id to use for requests.
   * This is used when there is multi-account support enabled in the Nylas Identity settings.
   *
   * @param grantId
   */
  setActiveGrantId(grantId: string) {
    this.activeGrantId = grantId;
  }

  /**
   * This method makes a request to the Nylas API, using the Nylas Identity's fetch method.
   *
   * The `grants/me/` prefix is added because all requests made from the browser to the Nylas API
   * must be made on behalf of the currently logged in user with a valid access token.
   *
   * @param NylasRequestArgs
   * @returns Promise<[T | null, NylasErrorResponse['error'] | null]>
   */
  async request<T>({ method, path, body, headers }: NylasRequestArgs): Promise<DataResponseReturnType<T>> {
    try {
      const response = await this.session.fetch(`grants/me/${path}`, {
        method,
        headers: headers ? new Headers(headers) : undefined,
        body,
        ...(this.session.isMultiAccount() && this.activeGrantId ? { grant_id: this.activeGrantId } : {}),
      });

      // Sometimes the Nylas identity session fetch method returns undefined.
      // Possibly due to CORS issues. In this case, we throw an error.
      if (typeof response === 'undefined') {
        throw new Error('Response is undefined');
      }

      return dataResponse(response as T);
    } catch (error: any) {
      let errorMessage = String(error);
      if (String(error).includes('Access token not found')) {
        errorMessage = 'Session expired, please log in again. Redirecting...';
        setTimeout(() => {
          localStorage.removeItem('grant');
          localStorage.removeItem('user');
          window.location.reload();
        }, 1000);
      }
      return dataResponse(null as T, {
        message: errorMessage,
      });
    }
  }

  /**
   * This method returns the current (logged in) user's information.
   * If the user is not logged in, it returns null.
   * @returns Promise<User | null>
   */
  async currentUser(): Promise<User | null> {
    const grantId = this.session.isMultiAccount() ? this.activeGrantId : undefined;
    const idToken = await this.session.getProfile(grantId);
    if (!idToken) {
      return null;
    }

    return {
      id: idToken.sub,
      email: idToken.email,
      name: idToken.name,
      provider: idToken.provider,
    };
  }

  /**
   * This method returns the URL to redirect the user to for authentication.
   * @param authConfig authArgs
   * @returns Promise<string | undefined>
   */
  async authenticationUrl(authArgs?: AuthArgs): Promise<string | undefined> {
    const provider = authArgs?.provider || 'google';
    const defaultArgs = this.defaultAuthArgs.find(args => args.provider === provider);
    let args = defaultArgs || {};

    if (authArgs) {
      args = { ...args, ...authArgs };
    }
    return this.session.auth(args);
  }

  /**
   * This method sets the default authentication arguments to use when authenticating the user.
   * @param authArgs authArgs
   */
  setDefaultAuthArgs(authArgs: AuthArgs[]) {
    this.defaultAuthArgs = authArgs;
  }
}
