@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-booking-confirmation-type {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .nylas-booking-confirmation-type__dropdown {
    span.label {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--nylas-base-800);

      tooltip-component {
        display: flex;
      }
    }

    select-dropdown::part(sd_dropdown) {
      width: 100%;
    }

    select-dropdown::part(sd_dropdown-button) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--nylas-base-200);
      border-radius: var(--nylas-border-radius-2x);
      padding: 1rem;
    }

    select-dropdown::part(sd_dropdown-content) {
      width: 100%;
      max-width: unset;
    }

    select-dropdown::part(sd_dropdown-button-selected-label) {
      max-width: calc(100% - 2rem);
      font-family: var(--nylas-font-family);
      font-size: 16px;
      line-height: 24px;
    }
  }
}
