import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { convertTo12HourFormat, convertTo24HourFormat, debug, minutesToTime, timeToMinutes } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { DEFAULT_OPEN_HOURS, TIMEZONE_MAP } from '@/common/constants';
import { Configuration } from '@nylas/core';

export type OpenHours = {
  days: number[];
  start: string;
  end: string;
  timezone: string;
};

export type Schedule = {
  SUN: { start: string; end: string }[];
  MON: { start: string; end: string }[];
  TUE: { start: string; end: string }[];
  WED: { start: string; end: string }[];
  THU: { start: string; end: string }[];
  FRI: { start: string; end: string }[];
  SAT: { start: string; end: string }[];
};

/**
 * The `nylas-availability-picker` component is a form input for selecting availability (open hours).
 * @part nap__header - The header of the availability picker
 * @part nap__select-timezone - The timezone selection container
 * @part nap__select-timezone-button - The timezone selection button
 * @part nap__select-timezone-dropdown-content - The timezone selection dropdown content
 * @part nap__availability - The availability container
 * @part nap__day - The day container
 * @part nap__time-ranges - The time ranges container
 * @part nap__time-range - The time range container
 * @part nap__add-time-range - The add time range button
 * @part nap__time-picker-container - The time picker container
 * @part nap__time-picker-input - The time picker input
 * @part nap__time-picker-times - The time picker times
 */
@Component({
  tag: 'nylas-availability-picker',
  styleUrl: 'nylas-availability-picker.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasAvailabilityPicker {
  /**
   * The element <nylas-availability-picker> itself.
   */
  @Element() host!: HTMLNylasAvailabilityPickerElement;
  /**
   * @standalone
   * The name of the availability picker.
   */
  @Prop() name: string = 'availability';
  /**
   * @standalone
   * The selected configuration.
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The open hours to display.
   */
  @Prop() openHours?: OpenHours[];
  /**
   * @standalone
   * The default timezone or preset timezone.
   */
  @Prop() defaultTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  /**
   * @standalone
   * Hide the header of the availability picker.
   */
  @Prop() hideHeader: boolean = false;

  /**
   * This event is fired when the selected availability / open hours change.
   * The value is a stringified JSON object with the open hours and timezone.
    ```
    {
      openHours: OpenHours[],
      timezone: string
    }
    ```
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;
  @State() schedule = {
    SUN: [],
    MON: [{ start: '09:00am', end: '05:00pm' }],
    TUE: [{ start: '09:00am', end: '05:00pm' }],
    WED: [{ start: '09:00am', end: '05:00pm' }],
    THU: [{ start: '09:00am', end: '05:00pm' }],
    FRI: [{ start: '09:00am', end: '05:00pm' }],
    SAT: [],
  };
  /**
   * The selected timezone state.
   */
  @State() timezone: string = '';

  /**
   * The overlapping time ranges state. This is used to display an error message when there are overlapping time ranges.
   */
  @State() overlapDays: { [key: string]: number[] } = {};

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-calendar-picker', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  configChangedHandler(newConfig: Configuration) {
    const defaultOpenHours = newConfig?.availability?.availability_rules?.default_open_hours ?? DEFAULT_OPEN_HOURS;
    // If the name is `availability`, we are setting the default open hours. If the name is `participant-****`, we are setting the participant's custom availability.
    // Set the timezone accordingly.
    const defaultOpenHoursTimezone = newConfig?.event_booking?.timezone ?? this.defaultTimezone;
    this.timezone = this.name === 'availability' ? defaultOpenHoursTimezone : this.defaultTimezone;
    if (this.openHours) {
      this.openHoursToSchedule(this.openHours);
    } else if (defaultOpenHours) {
      this.openHoursToSchedule(defaultOpenHours);
    }
  }

  @Watch('schedule')
  scheduleChanged(newValue: Schedule, oldValue: Schedule) {
    if (newValue !== oldValue) {
      let overlapDays: { [key: string]: number[] } = {};
      Object.keys(newValue).forEach(key => {
        const dayTimeRanges = newValue[key];
        if (dayTimeRanges.length > 0) {
          const overlaps = this.getOverlaps(dayTimeRanges);
          if (overlaps.length > 0) {
            overlapDays[key] = overlaps;
          }
        }
      });
      this.overlapDays = overlapDays;
      if (typeof this.internals.setFormValue !== 'function') {
        return;
      }
      if (Object.keys(overlapDays).length > 0) {
        const element = this.host.shadowRoot?.getElementById(Object.keys(overlapDays)[0]) as HTMLInputElement;
        if (element) {
          debug('nylas-availability-picker', 'The time ranges are overlapping. Overlap: ', overlapDays);
          this.internals.setValidity({ customError: true }, 'Overlapping time ranges found', element);
        }
      } else {
        this.internals.setValidity({ customError: false });
        this.internals.setFormValue(
          JSON.stringify({
            openHours: this.scheduleToOpenHours(this.schedule),
            timezone: this.timezone,
          }),
        );
      }
      this.valueChanged.emit({
        value: JSON.stringify({
          openHours: this.scheduleToOpenHours(newValue),
          timezone: this.timezone,
        }),
        name: this.name,
      });
    }
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(
    event: CustomEvent<{
      value: string;
      name: string;
    }>,
  ) {
    const { name, value } = event.detail;
    if (name === 'timezone') {
      this.timezone = value;
      if (typeof this.internals.setFormValue !== 'function') {
        return;
      }
      const updateValue = {
        openHours: this.scheduleToOpenHours(this.schedule),
        timezone: this.timezone,
      };
      this.internals.setFormValue(JSON.stringify(updateValue));
      this.valueChanged.emit({
        value: JSON.stringify(updateValue),
        name: this.name,
      });
    }
  }

  connectedCallback() {
    debug('nylas-availability-picker', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-availability-picker', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-availability-picker', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-availability-picker', 'componentDidLoad');

    if (this.selectedConfiguration) {
      this.configChangedHandler(this.selectedConfiguration);
    } else {
      this.timezone = this.defaultTimezone;
    }

    if (typeof this.internals.setFormValue !== 'function') {
      return;
    }
    const updateValue = {
      openHours: this.scheduleToOpenHours(this.schedule),
      timezone: this.timezone,
    };
    this.internals.setFormValue(JSON.stringify(updateValue));
    this.valueChanged.emit({
      value: JSON.stringify(updateValue),
      name: this.name,
    });
  }

  getOverlaps(timeRanges) {
    // Convert times to minutes and add to the array
    let timeRangesInMinutes = timeRanges.map((range, index) => ({
      start: timeToMinutes(range.start),
      end: timeToMinutes(range.end),
      originalIndex: index,
    }));

    // Sort by start time
    timeRangesInMinutes.sort((a, b) => a.start - b.start);
    let overlaps: number[] = [];
    // Check for overlap
    for (let i = 1; i < timeRangesInMinutes.length; i++) {
      if (timeRangesInMinutes[i].start < timeRangesInMinutes[i - 1].end) {
        // Add both overlapping time range indices if they are not already included
        if (!overlaps.includes(timeRangesInMinutes[i].originalIndex)) {
          overlaps.push(timeRangesInMinutes[i].originalIndex);
        }
        if (!overlaps.includes(timeRangesInMinutes[i - 1].originalIndex)) {
          overlaps.push(timeRangesInMinutes[i - 1].originalIndex);
        }
      }
    }
    return overlaps.sort((a, b) => a - b); // Return sorted list of indices
  }

  addTimeRange(day) {
    const currentTimeRanges = this.schedule[day];

    // Default working hours are from 00:00 to 23:45 for calculation purposes
    const dayStart = 0; // Start of the day in minutes (00:00)
    const dayEnd = 1425; // End of the day in minutes (23:45)

    if (currentTimeRanges.length === 0) {
      // If there are no existing time ranges, add one at the start of the day
      this.schedule[day] = [{ start: '09:00am', end: '05:00pm' }]; // Example range
    } else {
      // Convert all time ranges to minutes for comparison
      const timeRangesInMinutes = currentTimeRanges
        .map(range => ({
          start: timeToMinutes(range.start),
          end: timeToMinutes(range.end),
        }))
        .sort((a, b) => a.start - b.start); // Sort by start time

      // Attempt to add a new time range at the end of the day
      const lastRangeEnd = timeRangesInMinutes[timeRangesInMinutes.length - 1].end;
      if (lastRangeEnd + 60 <= dayEnd) {
        // There's room at the end of the day
        this.schedule[day].push({
          start: convertTo12HourFormat(minutesToTime(lastRangeEnd)),
          end: convertTo12HourFormat(minutesToTime(lastRangeEnd + 60)),
        });
      } else {
        // No room at the end, look for gaps at the beginning of the day
        let gapFound = false;
        if (timeRangesInMinutes[0].start > dayStart + 60) {
          // There's room for at least a 1-hour meeting at the beginning of the day
          this.schedule[day].push({
            start: convertTo12HourFormat(minutesToTime(dayStart)),
            end: convertTo12HourFormat(minutesToTime(dayStart + 60)),
          });
          gapFound = true;
        }

        if (!gapFound) {
          // Search for gaps between scheduled time ranges
          for (let i = 0; i < timeRangesInMinutes.length - 1; i++) {
            const currentEnd = timeRangesInMinutes[i].end;
            const nextStart = timeRangesInMinutes[i + 1].start;

            if (nextStart - currentEnd >= 60) {
              // Found a gap
              this.schedule[day].push({
                start: convertTo12HourFormat(minutesToTime(currentEnd)),
                end: convertTo12HourFormat(minutesToTime(currentEnd + 60)),
              });
              break; // Exit the loop after adding a time range
            }
          }
        }
      }
    }

    // Sort the updated schedule to maintain order
    this.schedule[day].sort((a, b) => timeToMinutes(a.start) - timeToMinutes(b.start));

    this.schedule = { ...this.schedule };
  }

  removeTimeRange(day, index) {
    this.schedule[day].splice(index, 1);
    this.schedule = { ...this.schedule };
  }

  @Listen('timeChange')
  setTime(event: CustomEvent<{ key: string; value: string }>) {
    const { key, value } = event.detail;
    const [dayIndex, timeType] = key.split('_');
    const [day, index] = dayIndex.split(':');

    if (timeType === 'start') {
      this.schedule[day][index].start = value;
    } else if (timeType === 'end') {
      this.schedule[day][index].end = value;
    }
    this.internals.setValidity({ customError: false });
    this.schedule = { ...this.schedule };
  }

  @Listen('formError')
  setFormError(event: CustomEvent<{ key: string; message: string }>) {
    const { key } = event.detail;
    const [_, timeType] = key.split('_');
    const element = this.host.shadowRoot?.getElementById(key);
    if (element) {
      this.internals.setValidity({ customError: true }, `Invalid ${timeType} time`, element as HTMLInputElement);
    }
  }

  openHoursToSchedule(openHours: OpenHours[]) {
    const newSchedule = {
      SUN: [],
      MON: [],
      TUE: [],
      WED: [],
      THU: [],
      FRI: [],
      SAT: [],
    };
    openHours.forEach(openHour => {
      openHour.days.forEach(day => {
        const dayKey = this.getDayKey(day);
        const start12hr = convertTo12HourFormat(openHour.start);
        const end12hr = convertTo12HourFormat(openHour.end);
        const timeRange = { start: start12hr, end: end12hr };

        // Check if the time range already exists for the day
        let timeRangeExists = false;
        if (newSchedule[dayKey]) {
          // Search for an existing time range that matches the current one
          timeRangeExists = newSchedule[dayKey].some(range => range.start === timeRange.start && range.end === timeRange.end);
        }

        if (!timeRangeExists) {
          if (newSchedule[dayKey]) {
            newSchedule[dayKey].push(timeRange);
          } else {
            newSchedule[dayKey] = [timeRange];
          }
        }
      });
    });

    this.schedule = newSchedule;
  }

  getDayKey(dayIndex: number): string {
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    return days[dayIndex];
  }

  scheduleToOpenHours(schedule: Schedule): OpenHours[] {
    const dayKeys = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    let openHoursMap: Map<string, { days: number[]; start: string; end: string }> = new Map();

    dayKeys.forEach((dayKey, index) => {
      if (schedule[dayKey]) {
        // Check if schedule for the day exists
        schedule[dayKey].forEach(timeRange => {
          const start24hr = convertTo24HourFormat(timeRange.start);
          const end24hr = convertTo24HourFormat(timeRange.end);
          const key = `${start24hr}-${end24hr}`;

          if (!openHoursMap.has(key)) {
            openHoursMap.set(key, { days: [index], start: start24hr, end: end24hr });
          } else {
            let entry = openHoursMap.get(key);
            if (entry) {
              entry.days.push(index);
              openHoursMap.set(key, entry);
            }
          }
        });
      }
    });

    let selectedOpenHours: OpenHours[] = [];
    openHoursMap.forEach((value, _key) => {
      selectedOpenHours.push({
        days: value.days,
        start: value.start,
        end: value.end,
        timezone: this.timezone,
      });
    });

    return selectedOpenHours;
  }

  @RegisterComponent<NylasAvailabilityPicker, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-availability-picker',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    fireRegisterEvent: true,
  })
  render() {
    const timezoneOptions = Object.keys(TIMEZONE_MAP).map(key => ({
      label: TIMEZONE_MAP[key],
      value: key,
    }));
    const selectedTimezoneOption = timezoneOptions.find(i => i.value === this.timezone);

    return (
      <Host>
        <div class="nylas-availability-picker" part="nap">
          {!this.hideHeader && (
            <div class="header" part="nap__header">
              <h3>Default open hours</h3>
              <p>
                Set when you're regularly available for event bookings.
                <tooltip-component>
                  <info-icon slot="tooltip-icon" />
                  <span slot="tooltip-content">This is the default availability for participants who don’t have availability set on the Participants tab.</span>
                </tooltip-component>
              </p>
            </div>
          )}
          <div class="content">
            <div class="select-timezone" part="nap__select-timezone">
              <h4 class="sub-header">Select timezone</h4>
              {selectedTimezoneOption?.label && (
                <select-dropdown
                  name="timezone"
                  exportparts="sd_dropdown: nap__timezone-container, sd_dropdown-button: nap__timezone-button, sd_dropdown-content: nap__timezone-dropdown-content"
                  options={timezoneOptions}
                  defaultSelectedOption={selectedTimezoneOption}
                >
                  <span slot="select-icon">
                    <globe-icon width="20" height="20" />
                  </span>
                </select-dropdown>
              )}
            </div>
            <div class="availability" part="nap__availability">
              {Object.keys(this.schedule).map(key => {
                const day = key;
                const timeRanges = this.schedule[key] as { start: string; end: string }[];
                return (
                  <div class="availability-day">
                    <div class="day" part="nap__day">
                      <input
                        type="checkbox"
                        name={day}
                        id={day}
                        checked={timeRanges.length > 0}
                        onClick={() => {
                          if (timeRanges.length > 0) {
                            this.schedule[day] = [];
                          } else {
                            this.schedule[day] = [{ start: '09:00am', end: '05:00pm' }];
                          }
                          this.schedule = { ...this.schedule };
                        }}
                      />
                      <label htmlFor={day} aria-label="Select day">
                        {day}
                      </label>
                    </div>
                    <div class="time-ranges" part="nap__time-ranges">
                      {timeRanges.length ? null : <span class="unavailable">Unavailable</span>}
                      {timeRanges.length > 0 &&
                        timeRanges.map((timeRange, timeRangeIndex) => {
                          const startKey = `${key}:${timeRangeIndex}_start`;
                          const endKey = `${key}:${timeRangeIndex}_end`;
                          return (
                            <div class="time-range" part="nap__time-range">
                              <div class="pickers">
                                <nylas-time-window-picker
                                  id={startKey}
                                  hasError={this.overlapDays[day]?.includes(timeRangeIndex)}
                                  time={timeRange.start}
                                  name={startKey}
                                  key={startKey}
                                  exportparts="time-picker: nap__time-picker-container, time-input: nap__time-picker-input, times: nap__time-picker-times"
                                />
                                <span> - </span>
                                <nylas-time-window-picker
                                  id={endKey}
                                  hasError={this.overlapDays[day]?.includes(timeRangeIndex)}
                                  time={timeRange.end}
                                  name={endKey}
                                  key={endKey}
                                  minimumStartTime={timeRange.start}
                                  exportparts="time-picker: nap__time-picker-container, time-input: nap__time-picker-input, times: nap__time-picker-times"
                                />
                              </div>
                              <button onClick={() => this.removeTimeRange(day, timeRangeIndex)}>
                                <close-icon />
                              </button>
                            </div>
                          );
                        })}
                      <p class="error">{this.overlapDays[day] ? 'Overlapping time ranges' : ''}</p>
                    </div>
                    <div>
                      {timeRanges.length > 0 ? (
                        <button onClick={() => this.addTimeRange(day)} part="nap__add-time-range">
                          <add-circle-icon />
                        </button>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
