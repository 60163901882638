import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-customize-booking-settings` component is a UI component that displays the booking calendar picker.
 * @part ncbs - The booking calendar picker container
 * @part ncbs__header - The header of the booking calendar picker
 * @part ncbs__settings - The settings container
 * @part ncbs__settings-div - The div inside the settings container that contains the checkbox and tooltip for each setting
 * @part ncbs__additional_guests - The additional guests setting
 * @part ncbs__cancellation_options - The cancellation options setting
 * @part ncbs__rescheduling_options - The rescheduling options setting
 */
@Component({
  tag: 'nylas-customize-booking-settings',
  styleUrl: 'nylas-customize-booking-settings.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasCustomizeBookingSettings {
  @Element() host!: HTMLNylasCustomizeBookingSettingsElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The default additional guests hidden setting as set in the configuration.
   */
  @Prop() defaultHideAdditionalGuests: boolean = this.selectedConfiguration?.scheduler?.hide_additional_guests ?? false;

  /**
   * @standalone
   * The default hide cancellation options setting as set in the configuration.
   */
  @Prop() defaultHideCancellationOptions: boolean = this.selectedConfiguration?.scheduler?.hide_cancellation_options ?? false;

  /**
   * @standalone
   * The default hide rescheduling options setting as set in the configuration.
   */
  @Prop() defaultHideReschedulingOptions: boolean = this.selectedConfiguration?.scheduler?.hide_rescheduling_options ?? false;

  /**
   * @standalone
   * The name of the calendar picker.
   */
  @Prop() name: string = 'customize-booking-settings';

  /**
   * State to store the allowed additional guests setting.
   */
  @State() allowGuestsToInvite!: boolean;

  /**
   * State to store the hide cancel booking setting.
   */
  @State() hideCancellationOptions!: boolean;

  /**
   * State to store the hide reschedule booking setting.
   */
  @State() hideReschedulingOptions!: boolean;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * This event is fired when the selected booking calendar changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-customize-booking-settings', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-customize-booking-settings', 'selectedConfigurationChangedHandler', newValue);
    this.allowGuestsToInvite = !newValue?.scheduler?.hide_additional_guests;
    this.hideCancellationOptions = newValue?.scheduler?.hide_cancellation_options ?? false;
    this.hideReschedulingOptions = newValue?.scheduler?.hide_rescheduling_options ?? false;
  }

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-customize-booking-settings', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-customize-booking-settings', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-customize-booking-settings', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-customize-booking-settings', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      // Set the default values if the selected configuration is not available
      this.allowGuestsToInvite = !this.defaultHideAdditionalGuests;
      this.hideCancellationOptions = this.defaultHideCancellationOptions;
      this.hideReschedulingOptions = this.defaultHideReschedulingOptions;
    }
  }

  componentWillUpdate() {
    debug('nylas-customize-booking-settings', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-customize-booking-settings', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-customize-booking-settings', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-customize-booking-settings', 'componentDidRender');
  }

  @Listen('nylasFormCheckboxToggled')
  checkboxToggledHandler(event: CustomEvent<{ name: string; checked: boolean }>) {
    const { name, checked } = event.detail;
    switch (name) {
      case 'hide_additional_guests':
        this.allowGuestsToInvite = checked;
        break;
      case 'hide_cancellation_options':
        this.hideCancellationOptions = checked;
        break;
      case 'hide_rescheduling_options':
        this.hideReschedulingOptions = checked;
        break;
    }
    this.valueChanged.emit({
      value: JSON.stringify({
        additionalGuestsHidden: !this.allowGuestsToInvite,
        hideCancellationOptions: this.hideCancellationOptions,
        hideReschedulingOptions: this.hideReschedulingOptions,
      }),
      name: this.name,
    });
    this.internals.setFormValue(
      JSON.stringify({
        additionalGuestsHidden: !this.allowGuestsToInvite,
        hideCancellationOptions: this.hideCancellationOptions,
        hideReschedulingOptions: this.hideReschedulingOptions,
      }),
      this.name,
    );
  }

  @RegisterComponent<NylasCustomizeBookingSettings, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-customize-booking-settings',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    fireRegisterEvent: true,
  })
  render() {
    const showComponent = typeof this.hideCancellationOptions === 'boolean' && typeof this.hideReschedulingOptions === 'boolean' && typeof this.allowGuestsToInvite === 'boolean';

    return (
      <Host>
        {showComponent && (
          <div class="nylas-customize-booking-settings" part="ncbs">
            <div class="header" part="ncbs__header">
              <h3>Additional settings</h3>
              <p>Booking settings for the event.</p>
            </div>

            <div class="nylas-customize-booking-settings__settings" part="ncbs__settings">
              <div class="nylas-customize-booking-settings__settings-div" part="ncbs__settings-div , ncbs__additional_guests">
                <checkbox-component name="hide_additional_guests" label="Allow guests to invite additional guests." checked={this.allowGuestsToInvite}>
                  <span slot="label" class="label">
                    <p class="label-contents">Allow guests to invite additional guests.</p>
                  </span>
                </checkbox-component>
              </div>
              <div class="nylas-customize-booking-settings__settings-div" part="ncbs__settings-div , ncbs__rescheduling_options">
                <checkbox-component name="hide_rescheduling_options" label="Hide 'Reschedule' option on booking page and emails." checked={this.hideReschedulingOptions}>
                  <span slot="label" class="label">
                    <p class="label-contents">Hide rescheduling option.</p>
                    <tooltip-component id="reschedule">
                      <info-icon slot="tooltip-icon" />
                      <span slot="tooltip-content">
                        Hide the&nbsp;<strong>Reschedule</strong>&nbsp;button in the booking confirmation and email notifications.
                      </span>
                    </tooltip-component>
                  </span>
                </checkbox-component>
              </div>
              <div class="nylas-customize-booking-settings__settings-div" part="ncbs__settings-div , ncbs__cancellation_options">
                <checkbox-component name="hide_cancellation_options" label="Hide 'Cancel' option on booking page and emails." checked={this.hideCancellationOptions}>
                  <span slot="label" class="label">
                    <p class="label-contents">Hide cancellation option.</p>
                    <tooltip-component id="cancel">
                      <info-icon slot="tooltip-icon" />
                      <span slot="tooltip-content">
                        Hide the&nbsp;<strong>Cancel</strong>&nbsp;button in the booking confirmation and email notifications.
                      </span>
                    </tooltip-component>
                  </span>
                </checkbox-component>
              </div>
            </div>
          </div>
        )}
      </Host>
    );
  }
}
