@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-calendar-picker {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);
  .nylas-calendar-picker__loading {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 1rem 1rem 0;
      color: var(--nylas-base-800);
      tooltip-component {
        display: flex;
      }
    }
    .loading-button {
      margin: 0 1rem;
      padding: 0;
      width: inherit;
      height: 48px;
      color: black;
      font-size: 1rem;
      cursor: not-allowed;
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      border: 1px solid var(--nylas-base-200);
      border-radius: var(--nylas-border-radius-2x);
      span {
        padding: 0 1rem;
      }
    }
  }
  .nylas-calendar-picker__dropdown {
    padding: 1rem;
    multi-select-dropdown::part(msd_dropdown) {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    multi-select-dropdown::part(msd_dropdown-button) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--nylas-base-200);
      border-radius: var(--nylas-border-radius-2x);
      padding: 1rem;
    }
    multi-select-dropdown::part(msd_dropdown-button--error) {
      border: 1px solid var(--nylas-error);
    }
    multi-select-dropdown::part(msd_dropdown-content) {
      width: 100%;
      max-width: unset;
    }
    multi-select-dropdown::part(msd_dropdown-button-selected-label) {
      max-width: calc(100% - 2rem);
      font-family: var(--nylas-font-family);
      font-size: 16px;
      line-height: 24px;
    }
  }
}
