@import '../../../common/styles/variables.scss';
@import '../../../common/mixins/inputs.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-custom-event-slug {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);
  text-align: left;

  .header {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr auto;

    h3 {
      border-radius: var(--nylas-border-radius-2x);
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 20px;
      color: var(--nylas-base-900);
      text-align: left;
    }

    p {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
    }

    .drawer-toggle {
      display: flex;
      gap: 24px;
      align-items: center;

      .chevron {
        display: flex;
        align-self: center;
        cursor: pointer;

        &:hover,
        &:active {
          color: var(--nylas-primary);
        }

        &.open {
          transform: rotate(90deg);
        }

        &.closed {
          transform: rotate(270deg);
        }

        &.disabled {
          cursor: not-allowed;
          color: var(--nylas-base-300);

          &:hover {
            color: var(--nylas-base-300);
          }
        }
      }
    }
  }

  .nylas-custom-event-slug__body {
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--nylas-base-25);
    border-radius: 0 0 var(--nylas-border-radius-2x) var(--nylas-border-radius-2x);
    border-top: 1px solid var(--nylas-base-200);

    @media #{$mobile} {
      grid-template-columns: 1fr;
    }

    .nylas-custom-event-slug__section {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: var(--nylas-base-800);

      @media #{$mobile} {
        border: none;
      }

      .nylas-custom-event-slug__row {
        display: flex;
        align-items: center;
        gap: 1rem;

        label {
          display: flex;
          align-items: center;

          span.required {
            color: var(--nylas-error, #cc4841);
          }

          span.label-icon {
            margin-left: 4px;

            tooltip-component {
              display: flex;
            }
          }
        }

        p {
          margin: 0.25rem 0 0 0;
          font-size: 1rem;
          font-weight: 500;
          line-height: 24px;
          color: var(--nylas-base-900);
        }

        .input-container {
          width: 100%;
        }
      }
    }

    span.error-message {
      color: var(--nylas-error);
    }

    span.help-text {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
    }
  }

  .nylas-custom-event-slug__input-wrapper {
    @include textfield;
    width: -moz-available;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    gap: 0.125rem;
    border-radius: var(--nylas-border-radius-2x);
    z-index: 1;

    &.focus {
      outline: 2px solid var(--nylas-primary);
    }

    &.error {
      border-color: var(--nylas-error);
      outline: none;
    }

    input-component::part(ic__input) {
      outline: none;
      border: none;
      width: 100%;
      font-size: 1rem;
      height: auto;
      padding-left: 0px;
    }

    .prefix {
      z-index: 0;
      margin-top: 1px;
      color: var(--nylas-base-400);
      font-weight: 500;
      font-size: 24px;
    }

    .icon {
      margin-right: 0.25rem;
      padding: 0 0.75rem;
      height: 100%;
      display: flex;
      align-items: center;
      right: 0;
      cursor: pointer;
      user-select: none;

      &.disabled {
        cursor: not-allowed;
      }

      &:hover {
        svg path {
          fill: var(--nylas-primary);
        }
      }

      &:active {
        svg path {
          fill: var(--nylas-base-500);
        }
      }

      .copied {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        gap: 0.125rem;
      }
    }
  }
}