import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { Calendar } from '@nylas/core';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { User } from '@/common/nylas-api-request';

/**
 * The `nylas-booking-calendar-picker` component is a UI component that displays the booking calendar picker.
 * @part nbcp - The booking calendar picker container
 * @part nbcp__header - The header of the booking calendar picker
 * @part nbcp__input-label - The input label of the booking calendar picker
 * @part nbcp__dropdown - The dropdown container
 * @part nbcp__dropdown-button - The dropdown button
 * @part nbcp__dropdown-content - The dropdown content
 */
@Component({
  tag: 'nylas-booking-calendar-picker',
  styleUrl: 'nylas-booking-calendar-picker.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasBookingCalendarPicker {
  @Element() host!: HTMLNylasCalendarPickerElement;
  /**
   * @standalone
   * The calendars to choose from.
   */
  @Prop() calendars?: Calendar[];
  /**
   * @standalone
   * The current logged in user.
   */
  @Prop() currentUser?: User;
  /**
   * @standalone
   * The list of user's calendars.
   */
  @Prop() currentUserCalendars?: Calendar[];
  /**
   * @standalone
   * The name of the calendar picker.
   */
  @Prop() name: string = 'booking-calendar';
  /**
   * @standalone
   * The default selected calendar.
   */
  @Prop() defaultBookingCalendar: string = '';
  /**
   * The prop to hide the header.
   */
  @Prop() hideHeader: boolean = false;
  /**
   * Prop for express flow when creating a config
   */
  @Prop() expressFlow: boolean = false;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The selected calendars.
   */
  @State() selectedCalendar: string = '';

  /**
   * This event is fired when the selected booking calendar changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-booking-calendar-picker', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-booking-calendar-picker', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-booking-calendar-picker', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-booking-calendar-picker', 'componentDidLoad');
    const primaryCalendar = this.calendars?.find(calendar => calendar.is_primary)?.id;
    const firstCalendar = this.calendars?.[0]?.id;
    this.defaultBookingCalendar = this.defaultBookingCalendar || primaryCalendar || firstCalendar || '';
    this.isInternalsAvailable && this.internals.setFormValue(this.defaultBookingCalendar.toString(), this.name);
    this.selectedCalendar = this.defaultBookingCalendar;
  }

  componentWillUpdate() {
    debug('nylas-booking-calendar-picker', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-booking-calendar-picker', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-booking-calendar-picker', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-booking-calendar-picker', 'componentDidRender');
  }

  get isInternalsAvailable() {
    return this.internals !== undefined && typeof this.internals.setFormValue === 'function';
  }

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-booking-calendar-picker', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('currentUserCalendars')
  currentUserCalendarsChangedHandler(newValue: Calendar[]) {
    debug('nylas-booking-calendar-picker', 'currentUserCalendarsChangedHandler', newValue);
    this.currentUserCalendars = newValue;
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-booking-calendar-picker', 'nylasFormDropdownChangedHandler', event.detail);
    // Pass as handler so that if event.defaultPrevented by parent app, this will be skipped.
    this.isInternalsAvailable && this.internals.setValidity({});
    const valueChanged = (event: CustomEvent<{ value: string; name: string }>) => {
      const { value } = event.detail;
      this.selectedCalendar = value;
      this.isInternalsAvailable && this.internals.setFormValue(value.toString(), this.name);
    };
    this.valueChanged.emit({ value: event.detail.value, name: this.name, valueChanged });
  }

  @RegisterComponent<NylasBookingCalendarPicker, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-booking-calendar-picker',
    stateToProps: new Map([
      ['schedulerConfig.calendars', 'currentUserCalendars'],
      ['schedulerConfig.currentUser', 'currentUser'],
    ]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const currentUserCalendarOptions =
      this.currentUserCalendars?.map(calendar => {
        return {
          value: calendar.id,
          label: calendar.name,
        };
      }) ?? [];

    const optionsFromCalendars =
      this.calendars?.map(calendar => {
        return {
          value: calendar.id,
          label: calendar.name,
        };
      }) ?? [];
    const calendarOptions = optionsFromCalendars?.length > 0 ? optionsFromCalendars : currentUserCalendarOptions || [];
    const defaultOption = calendarOptions.find(cal => cal.value == this.defaultBookingCalendar);
    return (
      <Host>
        <div class="nylas-booking-calendar-picker" part="nbcp">
          {!this.hideHeader && (
            <div class="header" part="nbcp__header">
              <h3>Calendar booking</h3>
              <p>
                Select the calendar where you want to add event bookings.
                <tooltip-component>
                  <info-icon slot="tooltip-icon" />
                  <span slot="tooltip-content">The calendar will be used to create events.</span>
                </tooltip-component>
              </p>
            </div>
          )}
          {calendarOptions.length > 0 ? (
            <div class="nylas-booking-calendar-picker__dropdown">
              <select-dropdown
                name="booking-calendar"
                options={[{ value: '', label: 'Select an option' }, ...calendarOptions]}
                withSearch={false}
                required={this.expressFlow}
                emptyValue={'Select an option'}
                errorMessage="Please select a calendar"
                exportparts="sd_dropdown: nbcp__dropdown, sd_dropdown-button: nbcp__dropdown-button, sd_dropdown-content: nbcp__dropdown-content"
                defaultSelectedOption={defaultOption ? defaultOption : null}
              />
            </div>
          ) : (
            <div class="nylas-booking-calendar-picker__loading">
              <span class="label">
                Select a calendar to book meetings on <info-icon />
              </span>
              <div class="loading-button">
                <span>Loading...</span>
              </div>
            </div>
          )}
        </div>
      </Host>
    );
  }
}
