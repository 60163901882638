import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-buffer-time` component is a UI component that allows users to set buffer time before and after an event.
 *
 * @part nbt - The buffer time container
 * @part nbt__header - The header of the buffer time
 * @part nbt__body - The body of the buffer time
 * @part nbt__dropdown-before - The dropdown container for the before buffer time
 * @part nbt__dropdown-button-before - The dropdown button for the before buffer time
 * @part nbt__dropdown-content-before - The dropdown content for the before buffer time
 * @part nbt__dropdown-after - The dropdown container for the after buffer time
 * @part nbt__dropdown-button-after - The dropdown button for the after buffer time
 * @part nbt__dropdown-content-after - The dropdown content for the after buffer time
 * @part nbt__preview - The preview container
 */
@Component({
  tag: 'nylas-buffer-time',
  styleUrl: 'nylas-buffer-time.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasBufferTime {
  @Element() host!: HTMLNylasCalendarPickerElement;
  /**
   * @internal
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * The name of the calendar picker.
   */
  @Prop() name: string = 'buffer-time';
  /**
   * @standalone
   * The buffer time
   */
  @Prop() buffer: { before: number; after: number } = this.selectedConfiguration?.availability?.availability_rules?.buffer ?? { before: 0, after: 0 };
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The selected before buffer time.
   */
  @State() selectedBeforeBufferTime: number = this.buffer.before;

  /**
   * The selected after buffer time.
   */
  @State() selectedAfterBufferTime: number = this.buffer.after;

  @State() componentLoaded: boolean = false;

  /**
   * This event is fired when the selected buffer time is changed.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-buffer-time', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-buffer-time', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-buffer-time', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-buffer-time', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.selectedBeforeBufferTime = this.buffer.before;
      this.selectedAfterBufferTime = this.buffer.after;
    }
    this.componentLoaded = true;

    // TODO: Remove this when the internals in tests are fixed.
    if (typeof this.internals.setFormValue === 'function') {
      const bufferTime = {
        before: this.selectedBeforeBufferTime,
        after: this.selectedAfterBufferTime,
      };
      this.internals.setFormValue(JSON.stringify(bufferTime), this.name);
    }
  }

  componentWillUpdate() {
    debug('nylas-buffer-time', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-buffer-time', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-buffer-time', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-buffer-time', 'componentDidRender');
  }

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-buffer-time', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-buffer-time', 'selectedConfigurationChangedHandler', newValue);
    const buffer = newValue?.availability?.availability_rules?.buffer;
    this.selectedAfterBufferTime = buffer?.after ? buffer.after : this.buffer.after;
    this.selectedBeforeBufferTime = buffer?.before ? buffer.before : this.buffer.before;
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-buffer-time', 'nylasFormDropdownChangedHandler', event.detail);
    // Pass as handler so that if event.defaultPrevented by parent app, this will be skipped.
    const valueChanged = (event: CustomEvent<{ value: string; name: string }>) => {
      const { value, name } = event.detail;
      if (name === 'before-buffer-time') {
        this.selectedBeforeBufferTime = parseInt(value);
      } else if (name === 'after-buffer-time') {
        this.selectedAfterBufferTime = parseInt(value);
      }
      const bufferTime = {
        before: this.selectedBeforeBufferTime,
        after: this.selectedAfterBufferTime,
      };
      this.internals.setFormValue(JSON.stringify(bufferTime), 'booking-calendar');
    };
    this.valueChanged.emit({ ...event.detail, valueChanged });
  }

  renderPreview() {
    const totalSlots = 4;
    const slotHeight = 10; // The height for each 30-minute slot

    // Event slot height is constant
    const eventSlotHeight = slotHeight * 2;

    // Helper function to determine the fill of a slot based on the minutes selected
    const slotFill = minutes => {
      const fullSlots = Math.floor(minutes / 30);
      const partialFillHeight = ((minutes % 30) / 30) * slotHeight;

      return {
        fullSlots,
        partialFillHeight,
      };
    };

    // Helper function to create before slots
    const createBeforeSlots = () => {
      const { fullSlots, partialFillHeight } = slotFill(this.selectedBeforeBufferTime);

      return Array.from({ length: totalSlots }, (_, index) => {
        // Slot is active if its index is greater than the total slots minus the full slots minus one
        // and there are some minutes selected.
        const isActive = this.selectedBeforeBufferTime > 0 && index > totalSlots - fullSlots - 1;
        const isPartial = this.selectedBeforeBufferTime > 0 && index === totalSlots - fullSlots - 1 && partialFillHeight > 0;

        let slotStyle: {
          backgroundColor?: string;
          background?: string;
        } = {};
        if (isActive && !isPartial) {
          slotStyle.backgroundColor = 'var(--nylas-base-100)';
        } else if (isPartial) {
          slotStyle.background = `linear-gradient(to top, var(--nylas-base-100) ${partialFillHeight}px, transparent 0)`;
        }

        return (
          <div
            key={index}
            class={`slot ${isActive ? 'active' : ''}`}
            style={{
              height: `${slotHeight}px`,
              ...slotStyle,
            }}
          ></div>
        );
      });
    };

    // Helper function to create after slots
    const createAfterSlots = () => {
      const { fullSlots, partialFillHeight } = slotFill(this.selectedAfterBufferTime);

      return Array.from({ length: totalSlots }, (_, index) => {
        const isActive = this.selectedAfterBufferTime > 0 && index < fullSlots;
        const isPartial = index === fullSlots && partialFillHeight > 0;

        let slotStyle: {
          backgroundColor?: string;
          background?: string;
        } = {};
        if (isActive && !isPartial) {
          slotStyle.backgroundColor = 'var(--nylas-base-100)';
        } else if (isPartial) {
          slotStyle.background = `linear-gradient(to bottom, var(--nylas-base-100) ${partialFillHeight}px, transparent 0)`;
        }

        return (
          <div
            key={index}
            class={`slot ${isActive ? 'active' : ''}`}
            style={{
              height: `${slotHeight}px`,
              ...slotStyle,
            }}
          ></div>
        );
      });
    };

    return (
      <div class="preview-container">
        {createBeforeSlots()}
        <div class="event-slot" style={{ height: `${eventSlotHeight}px` }}></div>
        {createAfterSlots()}
      </div>
    );
  }

  @RegisterComponent<NylasBufferTime, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-buffer-time',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const minuteOptions = Array.from({ length: 25 }, (_, i) => {
      const value = i * 5;
      return {
        label: value.toString(),
        value: value,
      };
    });

    return (
      <Host>
        <div class="nylas-buffer-time" part="nbt">
          <div class="header" part="nbt__header">
            <h3>Buffer time</h3>
            <p>
              Require empty buffer time before and after an event.
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">Scheduler does not book the buffer time.</span>
              </tooltip-component>
            </p>
          </div>
          <div class="nylas-buffer-time__body" part="nbt__body">
            <div class="nylas-buffer-time__dropdown">
              <div class="nylas-buffer-time__row">
                <label>Before the event</label>
                <div class="dropdown-container">
                  {this.componentLoaded && (
                    <select-dropdown
                      id="before-buffer-time"
                      withSearch={false}
                      name="before-buffer-time"
                      exportparts="sd_dropdown: nbt__dropdown-before, sd_dropdown-button: nbt__dropdown-button-before, sd_dropdown-content: nbt__dropdown-content-before"
                      options={minuteOptions}
                      defaultSelectedOption={minuteOptions.find(min => min.value == this.selectedBeforeBufferTime)}
                    />
                  )}
                  <span>mins</span>
                </div>
              </div>
              <div class="nylas-buffer-time__row">
                <label>After the event</label>
                <div class="dropdown-container">
                  {this.componentLoaded && (
                    <select-dropdown
                      id="after-buffer-time"
                      withSearch={false}
                      name="after-buffer-time"
                      exportparts="sd_dropdown: nbt__dropdown-after, sd_dropdown-button: nbt__dropdown-button-after, sd_dropdown-content: nbt__dropdown-content-after"
                      options={minuteOptions}
                      defaultSelectedOption={minuteOptions.find(min => min.value == this.selectedAfterBufferTime)}
                    />
                  )}
                  <span>mins</span>
                </div>
              </div>
            </div>
            <div class="nylas-buffer-time__preview" part="nbt__preview">
              <h4>PREVIEW</h4>
              {this.renderPreview()}
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
