@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-availability-picker {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);

  .header {
    padding: 1rem;

    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: var(--nylas-base-900);
      text-align: left;
    }

    p {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .content {
    padding: 1rem;
    color: var(--nylas-base-900);
    font-size: 16px;
    font-family: var(--nylas-font-family);
    border-top: 1px solid var(--nylas-base-200);

    @media #{$small-mobile} {
      font-size: 15px;
    }

    .select-timezone {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--nylas-base-200);
      padding-bottom: 1rem;
      gap: 1rem;

      h4 {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin: 0;
        font-size: 16px;

        @media #{$small-mobile} {
          font-size: 15px;
        }

        font-weight: 500;
        line-height: 20px;
      }
    }

    .availability {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0 0.5rem;

      @media #{$small-mobile} {
        gap: 0.5rem;
        padding: 0;
      }

      margin: 1rem 0;

      .availability-day {
        display: grid;
        grid-template-columns: minmax(auto, 65px) 1fr minmax(auto, 40px);

        @media #{$mobile} {
          grid-template-columns: 78px 1fr auto;
        }

        @media #{$small-mobile} {
          display: inline-grid;
          gap: 8px;
          grid-template-columns: 60px 1fr auto;
        }

        gap: 1rem;
        align-items: baseline;

        .day {
          display: flex;
          gap: 0.5rem;

          @media #{$small-mobile} {
            gap: 0.25rem;
          }

          align-items: center;

          input[type='checkbox'] {
            transform: scale(1.3);
          }
        }

        .time-ranges {
          span.unavailable {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .time-range {
            display: flex;
            gap: 1.5rem;
            justify-content: space-between;
            align-items: baseline;

            @media #{$small-mobile} {
              justify-content: unset;
              gap: 0.5rem;
            }

            .pickers {
              display: flex;
              gap: 1rem;

              @media #{$small-mobile} {
                gap: 0.5rem;
              }

              span {
                margin-top: 0.75rem;
              }
            }

            &:not(:last-of-type) {
              margin-bottom: 1rem;
            }
          }

          p.error {
            color: var(--nylas-error);
          }
        }

        button {
          border: none;
          background: transparent;
          height: 40px;
          cursor: pointer;
          color: var(--nylas-base-800);

          &:hover,
          &:active {
            color: var(--nylas-primary);
          }
        }
      }
    }
  }
}

select-dropdown {
  width: auto;
}

select-dropdown::part(sd_dropdown-button) {
  justify-content: initial;
}
